import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Snackbar,
  Alert,
  Typography,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { useAuth } from '../../context/AuthContext';

function AgregarContacto({ open, onClose, onAgregar, contactosExistentes }) {
  const [tipo, setTipo] = useState('Persona');
  const [nuevoContacto, setNuevoContacto] = useState({
    nombre: '',
    telefono: '',
    email: '',
    nif: '',
    organizacion_id: '',
    empresaNombre: '',
    contactoEmpresaId: '',
    pais: '',
    provincia: '',
    ciudad: '',
    direccion: '',
  });
  const [empresaEncontrada, setEmpresaEncontrada] = useState(null);
  const [empresaSeleccionada, setEmpresaSeleccionada] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [empresasDisponibles, setEmpresasDisponibles] = useState([]);
  const [paises, setPaises] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [ciudades, setCiudades] = useState([]);
  const { user, currentOrganization } = useAuth();

  const db = getFirestore();

  useEffect(() => {
    if (contactosExistentes && currentOrganization) {
      const empresas = contactosExistentes.filter(contacto => contacto.tipo === 'Empresa');
      setEmpresasDisponibles(empresas);
    }
    fetchPaises();
  }, [contactosExistentes, currentOrganization]);

  const handleTipoChange = (event) => {
    setTipo(event.target.value);
    setNuevoContacto({
      nombre: '',
      telefono: '',
      email: '',
      nif: '',
      organizacion_id: '',
      empresaNombre: '',
      contactoEmpresaId: '',
      pais: '',
      provincia: '',
      ciudad: '',
      direccion: '',
      dni: '',
    });
    setEmpresaEncontrada(null);
    setEmpresaSeleccionada(null);
  };

  const handleChange = (e, fieldName, fieldValue) => {
    const name = fieldName || e.target.name;  // Usa el parámetro fieldName si está definido, si no toma e.target.name
    const value = fieldValue || e.target.value;  // Usa el parámetro fieldValue si está definido, si no toma e.target.value

    setNuevoContacto(prev => ({
      ...prev,
      [name]: value,
    }));
  };


  const handleEmpresaChange = (event) => {
    const contactoEmpresaId = event.target.value;
    const empresaSeleccionada = empresasDisponibles.find(empresa => empresa.id === contactoEmpresaId);
    if (empresaSeleccionada) {
      setNuevoContacto(prev => ({
        ...prev,
        contactoEmpresaId, // ID del contacto de tipo Empresa
        organizacion_id: empresaSeleccionada.organizacion_id, // organizacion_id de la empresa
        empresaNombre: empresaSeleccionada.nombre, // Nombre de la empresa
      }));
    } else {
      setNuevoContacto(prev => ({
        ...prev,
        contactoEmpresaId: '',
        organizacion_id: '',
        empresaNombre: '',
      }));
    }
  };


  const fetchPaises = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'paises'));
      const paisesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      paisesList.sort((a, b) => a.nombre_es.localeCompare(b.nombre_es));
      setPaises(paisesList);
      setDefaultCountry(paisesList);
    } catch (error) {
      console.error('Error fetching paises:', error);
    }
  };

  const fetchProvincias = async (paisId) => {
    try {

      const provincesCollection = collection(db, `paises/${paisId}/provinces`);
      const querySnapshot = await getDocs(provincesCollection);
      const provinciasList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      provinciasList.sort((a, b) => a.name_es.localeCompare(b.name_es));
      setProvincias(provinciasList);
    } catch (error) {
      console.error('Error fetching provincias:', error);
    }
  };

  const fetchCiudades = async (paisId, provinciaId) => {
    try {
      const citiesCollection = collection(db, `paises/${paisId}/provinces/${provinciaId}/localities`);
      const querySnapshot = await getDocs(citiesCollection);
      const ciudadesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      ciudadesList.sort((a, b) => a.name_es.localeCompare(b.name_es));
      setCiudades(ciudadesList);
    } catch (error) {
      console.error('Error fetching ciudades:', error);
    }
  };

  const setDefaultCountry = (paisesList) => {
    if (user && user.pais && user.pais.nombre_es) {
      console.log('User country:', user.pais.nombre_es);
      const userCountry = paisesList.find(pais => pais.nombre_es === user.pais.nombre_es);
      if (userCountry) {
        console.log('Setting default country:', userCountry.nombre_es);
        setNuevoContacto(prev => ({
          ...prev,
          pais: userCountry.nombre_es
        }));
      }
    }
  };

  useEffect(() => {
    if (nuevoContacto.pais) {
      const selectedPais = paises.find(pais => pais.nombre_es === nuevoContacto.pais);
      if (selectedPais) {
        fetchProvincias(selectedPais.id);
      }
    }
  }, [nuevoContacto.pais, paises]);

  useEffect(() => {
    if (nuevoContacto.provincia) {
      const selectedPais = paises.find(pais => pais.nombre_es === nuevoContacto.pais);
      const selectedProvincia = provincias.find(provincia => provincia.name_es === nuevoContacto.provincia);
      if (selectedPais && selectedProvincia) {
        fetchCiudades(selectedPais.id, selectedProvincia.id);
      }
    }
  }, [nuevoContacto.provincia, provincias, paises]);

  const buscarEmpresa = async () => {

    const organizacionesRef = collection(db, 'organizaciones');

    try {
      const querySnapshot = await getDocs(organizacionesRef);
      const empresas = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const normalizar = (str) => str?.toLowerCase().trim();
      const nif = normalizar(nuevoContacto.nif);
      const telefono = normalizar(nuevoContacto.telefono);
      const nombre = normalizar(nuevoContacto.nombre);

      const empresaEncontrada = empresas.find((empresa) =>
        (nif && empresa.nif && normalizar(empresa.nif) === nif) ||
        (telefono && empresa.telefono && normalizar(empresa.telefono) === telefono) ||
        (nombre && empresa.nombre && normalizar(empresa.nombre) === nombre)
      );

      if (empresaEncontrada) {
        setEmpresaEncontrada(empresaEncontrada);
        setSnackbar({ open: true, message: `Empresa encontrada: ${empresaEncontrada.nombre}`, severity: 'success' });
      } else {
        setEmpresaEncontrada(null);
        setSnackbar({ open: true, message: 'No se encontró ninguna empresa', severity: 'info' });
      }
    } catch (error) {
      console.error("Error al buscar empresa:", error);
      setSnackbar({ open: true, message: 'Error al buscar empresa', severity: 'error' });
    }
  };

  const seleccionarEmpresa = () => {
    setEmpresaSeleccionada(empresaEncontrada);
    setSnackbar({ open: true, message: `Empresa "${empresaEncontrada.nombre}" seleccionada`, severity: 'success' });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let contactoFinal;

    if (tipo === 'Persona') {
      contactoFinal = {
        tipo: 'Persona',
        nombre: nuevoContacto.nombre,
        telefono: nuevoContacto.telefono,
        email: nuevoContacto.email,
        pais: nuevoContacto.pais,
        provincia: nuevoContacto.provincia,
        ciudad: nuevoContacto.ciudad,
        direccion: nuevoContacto.direccion,
        dni: nuevoContacto.dni,
      };

      // Añadir organizacion_id y empresaNombre solo si se ha seleccionado una empresa
      if (nuevoContacto.contactoEmpresaId) {
        contactoFinal.organizacion_id = nuevoContacto.contactoEmpresaId;
        contactoFinal.empresaNombre = nuevoContacto.empresaNombre;
      }

      console.log('Contacto a agregar:', contactoFinal);
    } else if (tipo === 'Empresa') {
      // if (empresaSeleccionada) {
      contactoFinal = {
        tipo: 'Empresa',
        //organizacion_id: nuevoContacto.id,
        email: nuevoContacto.email,
        nombre: nuevoContacto.nombre,
        nif: nuevoContacto.nif,
        telefono: nuevoContacto.telefono,
        pais: nuevoContacto.pais,
        provincia: nuevoContacto.provincia,
        ciudad: nuevoContacto.ciudad,
        direccion: nuevoContacto.direccion,
      };
      /* } else {
         setSnackbar({ open: true, message: 'Por favor, busca y selecciona una empresa existente', severity: 'warning' });
         return;
       }*/
    } else {
      setSnackbar({ open: true, message: 'Por favor, selecciona un tipo de contacto', severity: 'warning' });
      return;
    }

    console.log('Contacto a agregar:', contactoFinal);
    onAgregar(contactoFinal);
    setTipo('');
    setNuevoContacto({ nombre: '', telefono: '', email: '', nif: '', organizacion_id: '', empresaNombre: '', contactoEmpresaId: '' });
    setEmpresaEncontrada(null);
    setEmpresaSeleccionada(null);
    onClose();
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          style: {
            backgroundColor: '#1E1E1E',
            borderRadius: '16px',
            color: '#FFFFFF'
          }
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{
          backgroundColor: '#2D3748',
          color: '#FFFFFF',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          Agregar Nuevo Contacto
          <IconButton onClick={onClose} sx={{ color: '#FFFFFF' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel
              sx={{ color: '#A0AEC0', '&.Mui-focused': { color: '#A0AEC0' } }}
            >Tipo de Contacto</InputLabel>
            <Select
              value={tipo}
              label="Tipo de Contacto"
              onChange={handleTipoChange}
              sx={{

                color: '#FFFFFF',
                '& .MuiOutlinedInput-notchedOutline': { borderColor: '#4A5568' },
                '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#718096' },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#7C3AED' },
                '& .MuiSelect-select': { color: '#FFFFFF' }, // Color del texto seleccionado

              }}
            >
              <MenuItem value="Persona">Persona</MenuItem>
              <MenuItem value="Empresa">Empresa</MenuItem>
            </Select>
          </FormControl>

          <form onSubmit={handleSubmit}>
            {tipo === 'Persona' ? (
              <>
                <TextField
                  autoFocus
                  margin="dense"
                  name="nombre"
                  label="Nombre"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={nuevoContacto.nombre}
                  onChange={handleChange}
                  required
                  sx={{ ...textFieldStyle }}
                />
                <TextField
                  margin="dense"
                  name="telefono"
                  label="Teléfono"
                  type="tel"
                  fullWidth
                  variant="outlined"
                  value={nuevoContacto.telefono}
                  onChange={handleChange}
                  sx={{ ...textFieldStyle }}
                />
                <TextField
                  margin="dense"
                  name="email"
                  label="Email"
                  type="email"
                  fullWidth
                  variant="outlined"
                  value={nuevoContacto.email}
                  onChange={handleChange}
                  sx={{ ...textFieldStyle, mb: 2 }}
                />
                <TextField
                  margin="dense"
                  name="dni"
                  label="Dni"
                  type="dni"
                  fullWidth
                  variant="outlined"
                  value={nuevoContacto.dni}
                  onChange={handleChange}
                  sx={{ ...textFieldStyle, mb: 2 }}
                />
                <FormControl fullWidth sx={{ ...textFieldStyle, mb: 2 }} required>
                  <InputLabel id="pais-label">País</InputLabel>
                  <Select
                    labelId="pais-label"
                    value={nuevoContacto.pais}
                    label="País"
                    onChange={(e) => handleChange(e, 'pais', e.target.value)}
                  >
                    {paises.map((pais) => (
                      <MenuItem key={pais.id} value={pais.nombre_es}>
                        {pais.nombre_es}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ ...textFieldStyle, mb: 2 }} required>
                  <InputLabel id="provincia-label">Provincia</InputLabel>
                  <Select
                    labelId="provincia-label"
                    value={nuevoContacto.provincia}
                    label="Provincia"
                    onChange={(e) => handleChange(e, 'provincia', e.target.value)}
                  >
                    {provincias.map((provincia) => (
                      <MenuItem key={provincia.id} value={provincia.name_es}>
                        {provincia.name_es}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ ...textFieldStyle, mb: 2 }} required>
                  <InputLabel id="ciudad-label">Ciudad</InputLabel>
                  <Select
                    labelId="ciudad-label"
                    value={nuevoContacto.ciudad}
                    label="Ciudad"
                    onChange={(e) => handleChange(e, 'ciudad', e.target.value)}
                  >
                    {ciudades.map((ciudad) => (
                      <MenuItem key={ciudad.id} value={ciudad.name_es}>
                        {ciudad.name_es}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  label="Dirección"
                  value={nuevoContacto.direccion}
                  onChange={(e) => handleChange(e, 'direccion', e.target.value)}
                  sx={{ ...textFieldStyle }}
                />
                <FormControl fullWidth margin="normal">
                  <InputLabel sx={{ color: '#A0AEC0', '&.Mui-focused': { color: '#A0AEC0' } }}>Empresa (Opcional)</InputLabel>
                  <Select
                    value={nuevoContacto.contactoEmpresaId || ''}
                    label="Empresa (Opcional)"
                    onChange={handleEmpresaChange}
                    sx={{
                      //backgroundColor: '#2D3748',
                      color: '#FFFFFF',
                      '& .MuiOutlinedInput-notchedOutline': { borderColor: '#4A5568' },
                      '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#718096' },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#7C3AED' },
                    }}
                  >
                    <MenuItem value="">Ninguna</MenuItem>
                    {empresasDisponibles.map((empresa) => (
                      <MenuItem key={empresa.id} value={empresa.id}>
                        {empresa.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            ) : (
              <>
                <TextField
                  margin="dense"
                  name="nif"
                  label="NIF"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={nuevoContacto.nif}
                  onChange={handleChange}
                  disabled={empresaSeleccionada}
                  sx={{ ...textFieldStyle }}
                />
                <TextField
                  margin="dense"
                  name="nombre"
                  label="Nombre"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={nuevoContacto.nombre}
                  onChange={handleChange}
                  disabled={empresaSeleccionada}
                  sx={{ ...textFieldStyle }}
                />
                <TextField
                  margin="dense"
                  name="email"
                  label="Email"
                  type="email"
                  fullWidth
                  variant="outlined"
                  value={nuevoContacto.email}
                  onChange={handleChange}
                  sx={{ ...textFieldStyle }}
                />
                <TextField
                  margin="dense"
                  name="telefono"
                  label="Teléfono"
                  type="tel"
                  fullWidth
                  variant="outlined"
                  value={nuevoContacto.telefono}
                  onChange={handleChange}
                  disabled={empresaSeleccionada}
                  sx={{ ...textFieldStyle }}
                />

                <FormControl fullWidth sx={{ ...textFieldStyle, mb: 2 }} required>
                  <InputLabel id="pais-label">País</InputLabel>
                  <Select
                    labelId="pais-label"
                    value={nuevoContacto.pais}
                    label="País"
                    onChange={(e) => handleChange(e, 'pais', e.target.value)}
                  >
                    {paises.map((pais) => (
                      <MenuItem key={pais.id} value={pais.nombre_es}>
                        {pais.nombre_es}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ ...textFieldStyle, mb: 2 }} required>
                  <InputLabel id="provincia-label">Provincia</InputLabel>
                  <Select
                    labelId="provincia-label"
                    value={nuevoContacto.provincia}
                    label="Provincia"
                    onChange={(e) => handleChange(e, 'provincia', e.target.value)}
                  >
                    {provincias.map((provincia) => (
                      <MenuItem key={provincia.id} value={provincia.name_es}>
                        {provincia.name_es}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ ...textFieldStyle, mb: 2 }} required>
                  <InputLabel id="ciudad-label">Ciudad</InputLabel>
                  <Select
                    labelId="ciudad-label"
                    value={nuevoContacto.ciudad}
                    label="Ciudad"
                    onChange={(e) => handleChange(e, 'ciudad', e.target.value)}
                  >
                    {ciudades.map((ciudad) => (
                      <MenuItem key={ciudad.id} value={ciudad.name_es}>
                        {ciudad.name_es}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  label="Dirección"
                  value={nuevoContacto.direccion}
                  onChange={(e) => handleChange(e, 'direccion', e.target.value)}
                  sx={{ ...textFieldStyle, mb: 2 }}
                />
                <Box>
                  <Button
                    onClick={buscarEmpresa}
                    variant="contained"
                    disabled={empresaSeleccionada}
                    sx={{
                      backgroundColor: '#7C3AED',
                      '&:hover': { backgroundColor: '#6D28D9' },
                      borderRadius: '8px',
                      textTransform: 'none',
                      fontWeight: 'bold',
                    }}
                  >
                    Buscar Empresa
                  </Button>
                </Box>
                {empresaEncontrada && !empresaSeleccionada && (
                  <Box>
                    <Typography color="#A0AEC0">Empresa encontrada: {empresaEncontrada.nombre}</Typography>
                    <Button
                      onClick={seleccionarEmpresa}
                      variant="contained"
                      color="primary"
                      sx={{
                        mt: 1,
                        backgroundColor: '#7C3AED',
                        '&:hover': { backgroundColor: '#6D28D9' },
                        borderRadius: '8px',
                        textTransform: 'none',
                        fontWeight: 'bold',
                      }}
                    >
                      Seleccionar esta empresa
                    </Button>
                  </Box>
                )}
                {empresaSeleccionada && (
                  <Box>
                    <Typography color="#4CAF50">
                      Empresa seleccionada: {empresaSeleccionada.nombre}
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </form>
        </DialogContent>
        <DialogActions sx={{ padding: '16px 24px' }}>
          <Button onClick={onClose} sx={{ color: '#A0AEC0' }}>Cancelar</Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={!tipo || (tipo === 'Empresa' && empresaSeleccionada)}
            sx={{
              backgroundColor: '#7C3AED',
              '&:hover': { backgroundColor: '#6D28D9' },
              borderRadius: '8px',
              textTransform: 'none',
              fontWeight: 'bold',
            }}
          >
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%', backgroundColor: '#2D3748', color: '#FFFFFF' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

const textFieldStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#4A5568',
    },
    '&:hover fieldset': {
      borderColor: '#718096',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#7C3AED',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#A0AEC0',
  },
  '& .MuiInputBase-input': {
    color: '#FFFFFF',
  },
};

export default AgregarContacto;