import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import {
  Typography,
  Box,
  TextField,
  Button,
  Container,
  Grid,
  MenuItem,
  IconButton,
  CircularProgress,
  Snackbar,
  Alert,
  Select,
  FormControl,
  InputLabel,
  Autocomplete,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getFirestore, collection, addDoc, doc, Timestamp, getDocs, getDoc } from 'firebase/firestore';
import { format, parseISO, addMinutes } from 'date-fns';
import { es } from 'date-fns/locale';

function AgregarActuacion() {
  const navigate = useNavigate();
  const { user, currentOrganization } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paises, setPaises] = useState([]);
  const [tiposEventos, setTiposEventos] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [ciudades, setCiudades] = useState([]);
  const [actuacion, setActuacion] = useState({
    nombre: '',
    inicio: '',
    direccion: '',
    provincia: '',
    ciudad: '',
    pais: {
      pais_id: '',
      nombre_es: '',
      nombre_en: '',
      iso: '',
      cantidad_minima: '',
    },
    espectadores: '',
    espacio_o_sala: '',
    estado: 'pendiente',
    clase: 'bolo_manual', // Añadimos esta línea
    space_type: '',
    cliente: {
      nombre: '',
      email: '',
      telefono: ''
    },
    tipo_bolo: {
      duracion: 0,
      nombre: '',
      precio: 0,
      enlace: ''
    },
    proveedor: {
      nombre: '',
      email: '',
      telefono: '',
      comision: 0
    },
    fecha: '',
    hora: '00:00',
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [contactos, setContactos] = useState([]);
  const db = getFirestore();

  const spaceTypes = [
    { value: 'indoor_prepared_room', label: 'Indoor Prepared Room' },
    { value: 'indoor_unique_event', label: 'Indoor Unique Event' },
    { value: 'outdoor_roofless_prepared_room', label: 'Outdoor Roofless Prepared Room' },
    { value: 'outdoor_roofless_unique_event', label: 'Outdoor Roofless Unique Event' },
    { value: 'other', label: 'Other' }
  ];

  const cargarContactos = useCallback(async () => {
    if (!currentOrganization) return;

    try {

      const contactosRef = collection(db, 'organizaciones', currentOrganization.organizacion_id, 'contactos');
      const snapshot = await getDocs(contactosRef);

      const contactosPromises = snapshot.docs.map(async (docSnapshot) => {
        const contactoData = docSnapshot.data();

        if (contactoData.tipo === 'Empresa' && contactoData.organizacion_id) {
          try {
            const empresaDocRef = doc(db, 'organizaciones', contactoData.organizacion_id);
            const empresaDoc = await getDoc(empresaDocRef);

            if (empresaDoc.exists()) {
              const empresaData = empresaDoc.data();
              return {
                id: docSnapshot.id,
                ...contactoData,
                nombre: empresaData.nombre || 'Nombre no disponible',
                email: empresaData.email || 'Email no disponible',
                telefono: empresaData.telefono || 'Teléfono no disponible',
                organizacion_id: contactoData.organizacion_id,
                label: `${empresaData.nombre} (Empresa)`
              };
            } else {
              return {
                id: docSnapshot.id,
                ...contactoData,
                nombre: 'Empresa no encontrada',
                email: 'N/A',
                telefono: 'N/A',
                label: 'Empresa no encontrada'
              };
            }
          } catch (empresaError) {
            console.error("Error al cargar datos de la empresa:", empresaError);
            return {
              id: docSnapshot.id,
              ...contactoData,
              nombre: 'Error al cargar empresa',
              email: 'N/A',
              telefono: 'N/A',
              label: 'Error al cargar empresa'
            };
          }
        } else {
          return {
            id: docSnapshot.id,
            ...contactoData,
            nombre: contactoData.nombre || 'Nombre no disponible',
            email: contactoData.email || 'Email no disponible',
            telefono: contactoData.telefono || 'Teléfono no disponible',
            label: `${contactoData.nombre} (${contactoData.tipo})`
          };
        }
      });

      const contactosList = await Promise.all(contactosPromises);
      setContactos(contactosList);
    } catch (error) {
      console.error('Error al cargar contactos:', error);
    }
  }, [currentOrganization]);

  const fetchPaises = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'paises'));
      const paisesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      paisesList.sort((a, b) => a.nombre_es.localeCompare(b.nombre_es));
      setPaises(paisesList);
      setDefaultCountry(paisesList);
    } catch (error) {
      console.error('Error fetching paises:', error);
    }
  };

  const fetchTiposBolos = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'tipos_eventos'));
      const tiposEventos = querySnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(evento => evento.es_bolo === true); // Filtra los eventos donde es_bolo es true
      setTiposEventos(tiposEventos);
    } catch (error) {
      console.error('Error fetching tipos de eventos:', error);
    }
  };


  const fetchProvincias = async (paisId) => {
    try {

      const provincesCollection = collection(db, `paises/${paisId}/provinces`);
      const querySnapshot = await getDocs(provincesCollection);
      const provinciasList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      provinciasList.sort((a, b) => a.name_es.localeCompare(b.name_es));
      setProvincias(provinciasList);
    } catch (error) {
      console.error('Error fetching provincias:', error);
    }
  };

  const fetchCiudades = async (paisId, provinciaId) => {
    try {
      const citiesCollection = collection(db, `paises/${paisId}/provinces/${provinciaId}/localities`);
      const querySnapshot = await getDocs(citiesCollection);
      const ciudadesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      ciudadesList.sort((a, b) => a.name_es.localeCompare(b.name_es));
      setCiudades(ciudadesList);
    } catch (error) {
      console.error('Error fetching ciudades:', error);
    }
  };

  useEffect(() => {
    if (actuacion.pais) {
      const selectedPais = paises.find(pais => pais.id === actuacion.pais.id);
      if (selectedPais) {
        fetchProvincias(selectedPais.id);
      }
    }
  }, [actuacion.pais, paises]);

  useEffect(() => {
    if (actuacion.provincia) {
      const selectedPais = paises.find(pais => pais.id === actuacion.pais.id);
      const selectedProvincia = provincias.find(provincia => provincia.name_es === actuacion.provincia);
      if (selectedPais && selectedProvincia) {
        fetchCiudades(selectedPais.id, selectedProvincia.id);
      }
    }
  }, [actuacion.provincia, provincias, paises]);

  useEffect(() => {
    if (!user) {
      navigate('/login');
    } else {
      console.log('User object:', user);
      fetchPaises();
      fetchTiposBolos();
      cargarContactos();

    }
  }, [user, navigate, cargarContactos]);

  useEffect(() => {
    if (actuacion.fecha && !esFechaValida(actuacion.fecha)) {
      console.error("Corrigiendo fecha inválida:", actuacion.fecha);
      setActuacion((prev) => ({
        ...prev,
        fecha: '', // Restablece el valor si es inválido
      }));
    }
  }, [actuacion.fecha]);


  const setDefaultCountry = (paisesList) => {
    if (user && user.pais && user.pais.nombre_es) {
      console.log('User country:', user.pais.nombre_es);
      const userCountry = paisesList.find(pais => pais.nombre_es === user.pais.nombre_es);
      if (userCountry) {
        console.log('Setting default country:', userCountry.nombre_es);
        setActuacion(prev => ({
          ...prev,
          pais: userCountry,
        }));
      }
    }
  };

  const convertirAISO = (fecha) => {
    if (!fecha || fecha.includes('NaN')) return ''; // Corrige errores
    const [day, month, year] = fecha.split('-');
    if (!day || !month || !year) return '';
    return `${year}-${month}-${day}`;
  };

  const handleProveedorChange = (event, newValue) => {
    if (newValue) {
      handleChange('proveedor', 'nombre', newValue.nombre);
      handleChange('proveedor', 'email', newValue.email);
      handleChange('proveedor', 'telefono', newValue.telefono);
    } else {
      handleChange('proveedor', 'nombre', '');
      handleChange('proveedor', 'email', '');
      handleChange('proveedor', 'telefono', '');
    }
  };

  const handleFechaInput = (e) => {
    const valor = e.target.value;

    // Asegúrate de que el formato sea válido (yyyy-mm-dd)
    if (!/^\d{0,4}(-\d{0,2}){0,2}$/.test(valor)) {
      e.preventDefault();
      return;
    }

    handleChange(null, 'fecha', valor);
  };



  const handleChange = (section, field, value) => {
    if (field === 'fecha' && !esFechaValida(value)) {
      console.error("Fecha inválida:", value);
      return;
    }
    if (field === 'pais') {
      const selectedPais = paises.find(pais => pais.id === value);
      setActuacion((prev) => ({
        ...prev,
        pais: selectedPais
      }));
    } else
      if (section) {
        setActuacion(prev => ({
          ...prev,
          [section]: {
            ...prev[section],
            [field]: value
          }
        }));
      } else {
        setActuacion(prev => ({
          ...prev,
          [field]: value
        }));
      }
  };

  const formatearFecha = (fecha, hora) => {
    if (!fecha || !hora) return ''; // Retorna vacío si falta algún dato

    try {
      const fechaCompleta = parseISO(`${fecha}T${hora}`);
      return format(fechaCompleta, "d 'de' MMMM 'de' yyyy, h:mm:ss a 'UTC+2'", { locale: es });
    } catch (error) {
      console.error('Error al formatear la fecha:', error);
      return '';
    }
  };

  const formatearFechaFin = (fecha) => {
    const fechaObj = typeof fecha === 'string' ? parseISO(fecha) : fecha;
    return format(fechaObj, "d 'de' MMMM 'de' yyyy, h:mm:ss a 'UTC+2'", { locale: es });
  };

  const obtenerFechaHoy = () => {
    const hoy = new Date();
    const anio = hoy.getFullYear();
    const mes = String(hoy.getMonth() + 1).padStart(2, '0'); // Mes en formato 2 dígitos
    const dia = String(hoy.getDate()).padStart(2, '0'); // Día en formato 2 dígitos
    return `${anio}-${mes}-${dia}`;
  };


  const esFechaValida = (fecha) => {
    // Valida el formato dd-mm-yyyy
    const regex = /^\d{2}-\d{2}-\d{4}$/;
    return regex.test(fecha) && !isNaN(Date.parse(convertirAISO(fecha))); // Asegura que sea una fecha válida
  };

  // Convierte de yyyy-mm-dd a dd-mm-yyyy
  const convertirAFormatoLocal = (fecha) => {
    const [year, month, day] = fecha.split('-');
    return `${day}-${month}-${year}`;
  };

  const convertirFechaISO = (fecha) => {
    const [dia, mes, anio] = fecha.split('-'); // Divide la fecha en día, mes y año
    return `${anio}-${mes}-${dia}`; // Devuelve en formato ISO
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccessMessage('');
    try {
      const { fecha, hora } = actuacion;

      const horaFinal = hora || '00:00';


      if (!fecha || !hora) {
        throw new Error('Debes especificar la fecha y la hora.');
      }

      console.log("Iniciando el proceso de guardar actuación");
      if (!user) {
        console.error("Usuario no autenticado:", user);
        throw new Error("Usuario no autenticado");
      }
      if (!currentOrganization || !currentOrganization.organizacion_id) {
        console.error("Organización no seleccionada:", currentOrganization);
        throw new Error("Organización no seleccionada");
      }
      console.log("Usuario autenticado:", user.id);
      console.log("Organización actual:", currentOrganization.organizacion_id);

      const fechaISO = convertirFechaISO(fecha); // Convertimos la fecha al formato ISO
      const fechaInicio = parseISO(`${fechaISO}T${horaFinal}`); // Ahora parseISO procesará correctamente
      const duracionMinutos = parseInt(actuacion.tipo_bolo.duracion) || 90; // Usa 90 minutos por defecto si no se especifica
      const fechaFin = addMinutes(fechaInicio, duracionMinutos);

      if (actuacion.tipo_bolo.tipo) {
        actuacion.tipo_bolo.nombre = actuacion.tipo_bolo.tipo; // Nombre por defecto
      }

      const actuacionConOrganizacion = {
        ...actuacion,
        organizacion_id: currentOrganization.organizacion_id,
        inicio: Timestamp.fromDate(fechaInicio),
        final: Timestamp.fromDate(fechaFin),
        inicioFormateado: formatearFecha(fechaISO, horaFinal),
        finalFormateado: formatearFechaFin(fechaFin),
        pais: actuacion.pais,
        provincia: actuacion.provincia,
        ciudad: actuacion.ciudad,
        direccion: actuacion.direccion,
      };

      console.log("Datos de la actuación a guardar:", actuacionConOrganizacion);

      const organizacionRef = doc(db, 'organizaciones', currentOrganization.organizacion_id);
      const eventosRef = collection(organizacionRef, 'eventos');
      console.log("Referencia de la colección:", eventosRef.path);

      const docRef = await addDoc(eventosRef, actuacionConOrganizacion);
      console.log("Evento agregado con ID:", docRef.id);

      setSuccessMessage(`Evento creado con ID: ${docRef.id}`);

      setTimeout(() => {
        navigate('/actuaciones');
      }, 2000);
    } catch (error) {
      console.error("Error detallado al añadir la actuación:", error);
      setError(`Error al guardar: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const textFieldStyle = {
    mb: 2,
    '& .MuiInputBase-input': {
      color: '#FFFFFF',
    },
    '& .MuiInputLabel-root': {
      color: '#A0AEC0',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#4A5568',
      },
      '&:hover fieldset': {
        borderColor: '#7C3AED',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#7C3AED',
      },
    },
    '& .MuiSelect-icon': {
      color: '#A0AEC0',
    },
  };

  const estados = ['Confirmado', 'Pendiente', 'Inactivo', 'Cancelado', 'Finalizado'];

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#000000' }}>
        <CircularProgress sx={{ color: '#7C3AED' }} />
      </Box>
    );
  }

  return (
    <Box sx={{ backgroundColor: '#000000', minHeight: '100vh', py: 4 }}>
      <Container maxWidth="lg">
        <IconButton
          onClick={() => navigate('/actuaciones')}
          sx={{ color: '#7C3AED', mb: 2 }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" component="h1" sx={{ color: '#FFFFFF', mb: 4 }}>
          Agregar Nueva Actuación
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            {/* Sección: Detalles del Evento */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" sx={{ color: '#FFFFFF', mb: 2 }}>Detalles del Evento</Typography>
              <TextField
                fullWidth
                label="Nombre de la Actuación"
                value={actuacion.nombre}
                onChange={(e) => handleChange(null, 'nombre', e.target.value)}
                sx={{ ...textFieldStyle, mb: 2 }}
                required
              />
              <FormControl fullWidth sx={{ ...textFieldStyle, mb: 2 }} required>
                <InputLabel id="tipo-bolo-label">Tipo de Bolo</InputLabel>
                <Select
                  labelId="tipo-bolo-label"
                  value={actuacion.tipo_bolo.tipo || ''}
                  onChange={(e) => handleChange('tipo_bolo', 'tipo', e.target.value)}
                  label="Tipo de Bolo"
                >
                  {tiposEventos.map((evento) => (
                    <MenuItem key={evento.id} value={evento.nombre_es}>
                      {evento.nombre_es}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                label="Fecha"
                type="date"
                value={convertirAISO(actuacion.fecha)}
                onChange={(e) => handleChange(null, 'fecha', convertirAFormatoLocal(e.target.value))}
                InputLabelProps={{ shrink: true }}
                sx={textFieldStyle}
                inputProps={{
                  min: obtenerFechaHoy(), // Fecha mínima permitida
                  max: '2100-12-31', // Fecha máxima permitida
                }}
                required
              />
              <TextField
                fullWidth
                label="Hora"
                type="time"
                value={actuacion.hora || '00:00'}
                onChange={(e) => handleChange(null, 'hora', e.target.value)}
                InputLabelProps={{ shrink: true }}
                sx={textFieldStyle}
              />
              <FormControl fullWidth sx={{ ...textFieldStyle, mb: 2 }} required>
                <InputLabel id="pais-label">País</InputLabel>
                <Select
                  labelId="pais-label"
                  value={actuacion.pais.id || ''}
                  label="País"
                  onChange={(e) => handleChange(e, 'pais', e.target.value)}
                >
                  {paises.map((pais) => (
                    <MenuItem key={pais.id} value={pais.id}>
                      {pais.nombre_es}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ ...textFieldStyle, mb: 2 }} required>
                <InputLabel id="provincia-label">Provincia</InputLabel>
                <Select
                  labelId="provincia-label"
                  value={actuacion.provincia}
                  label="Provincia"
                  onChange={(e) => handleChange(null, 'provincia', e.target.value)}
                >
                  {provincias.map((provincia) => (
                    <MenuItem key={provincia.id} value={provincia.name_es}>
                      {provincia.name_es}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ ...textFieldStyle, mb: 2 }} required>
                <InputLabel id="ciudad-label">Ciudad</InputLabel>
                <Select
                  labelId="ciudad-label"
                  value={actuacion.ciudad}
                  label="Ciudad"
                  onChange={(e) => handleChange(null, 'ciudad', e.target.value)}
                >
                  {ciudades.map((ciudad) => (
                    <MenuItem key={ciudad.id} value={ciudad.name_es}>
                      {ciudad.name_es}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                label="Dirección"
                value={actuacion.direccion}
                onChange={(e) => handleChange(null, 'direccion', e.target.value)}
                sx={{ ...textFieldStyle, mb: 2 }}
              />
              <TextField
                fullWidth
                label="Espectadores"
                type="number"
                value={actuacion.espectadores}
                onChange={(e) => handleChange(null, 'espectadores', e.target.value)}
                sx={{ ...textFieldStyle, mb: 2 }}
              />
              <TextField
                fullWidth
                label="Sala, Local, Localización"
                value={actuacion.espacio_o_sala}
                onChange={(e) => handleChange(null, 'espacio_o_sala', e.target.value)}
                sx={{ ...textFieldStyle, mb: 2 }}
              />
              <FormControl fullWidth sx={{ ...textFieldStyle, mb: 2 }} required>
                <InputLabel id="space-type-label">Tipo de Espacio</InputLabel>
                <Select
                  labelId="space-type-label"
                  value={actuacion.space_type}
                  label="Tipo de Espacio"
                  onChange={(e) => handleChange(null, 'space_type', e.target.value)}
                >
                  {spaceTypes.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ ...textFieldStyle, mb: 2 }} required>
                <InputLabel id="estado-label">Estado</InputLabel>
                <Select
                  labelId="estado-label"
                  value={actuacion.estado}
                  label="Estado"
                  onChange={(e) => handleChange(null, 'estado', e.target.value)}
                >
                  {estados.map((estado) => (
                    <MenuItem key={estado} value={estado.toLowerCase()}>
                      {estado}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Sección: Información del Cliente */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" sx={{ color: '#FFFFFF', mb: 2 }}>Información del Cliente</Typography>
              <Autocomplete
                options={contactos}
                getOptionLabel={(option) => option.label || ''}
                renderInput={(params) => <TextField {...params} label="Seleccionar Cliente" sx={{ ...textFieldStyle, mb: 2 }} />}
                onChange={(event, newValue) => {
                  if (newValue) {
                    handleChange('cliente', 'nombre', newValue.nombre);
                    handleChange('cliente', 'email', newValue.email);
                    handleChange('cliente', 'telefono', newValue.telefono);
                  } else {
                    handleChange('cliente', 'nombre', '');
                    handleChange('cliente', 'email', '');
                    handleChange('cliente', 'telefono', '');
                  }
                }}
              />
              {/**
               <TextField
  fullWidth
  label="Nombre del Cliente"
  value={actuacion.cliente.nombre}
  InputProps={{
    readOnly: true,
  }}
  sx={{ ...textFieldStyle, mb: 2 }}
/>
               */}

              <TextField
                fullWidth
                label="Email del Cliente"
                value={actuacion.cliente.email}
                InputProps={{
                  readOnly: true,
                }}
                sx={{ ...textFieldStyle, mb: 2 }}
              />
              <TextField
                fullWidth
                label="Teléfono del Cliente"
                value={actuacion.cliente.telefono}
                InputProps={{
                  readOnly: true,
                }}
                sx={{ ...textFieldStyle, mb: 2 }}
              />

              <Typography variant="h6" sx={{ color: '#FFFFFF', mb: 2, mt: 2 }}>Información del Proveedor</Typography>
              <Autocomplete
                options={contactos}
                getOptionLabel={(option) => option.label || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Seleccionar Proveedor"
                    sx={{ ...textFieldStyle, mb: 2 }}
                  />
                )}
                onChange={handleProveedorChange}
              />
              <TextField
                fullWidth
                label="Email del Proveedor"
                value={actuacion.proveedor.email}
                onChange={(e) => handleChange('proveedor', 'email', e.target.value)}
                sx={{ ...textFieldStyle, mb: 2 }}
              />
              <TextField
                fullWidth
                label="Teléfono del Proveedor"
                value={actuacion.proveedor.telefono}
                onChange={(e) => handleChange('proveedor', 'telefono', e.target.value)}
                sx={{ ...textFieldStyle, mb: 2 }}
              />
              {/* 
               <TextField
  fullWidth
  label="Comisión del Proveedor"
  value={actuacion.proveedor.comision}
  onChange={(e) => handleChange('proveedor', 'comision', e.target.value)}
  sx={{ ...textFieldStyle, mb: 2 }}
/>
               */}
            </Grid>


            {/* Sección: Detalles del Bolo */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" sx={{ color: '#FFFFFF', mb: 2 }}>Detalles del Bolo</Typography>
              <TextField
                fullWidth
                label="Duración (minutos)"
                type="number"
                value={actuacion.tipo_bolo.duracion}
                onChange={(e) => handleChange('tipo_bolo', 'duracion', e.target.value)}
                sx={{ ...textFieldStyle, mb: 2 }}
              />
              <TextField
                fullWidth
                label="Nombre del Bolo"
                value={actuacion.tipo_bolo.nombre}
                onChange={(e) => handleChange('tipo_bolo', 'nombre', e.target.value)}
                sx={{ ...textFieldStyle, mb: 2 }}
              />
              <TextField
                fullWidth
                label="Precio"
                type="number"
                value={actuacion.tipo_bolo.precio}
                onChange={(e) => handleChange('tipo_bolo', 'precio', e.target.value)}
                sx={{ ...textFieldStyle, mb: 2 }}
              />
              <TextField
                fullWidth
                label="Enlace"
                value={actuacion.tipo_bolo.enlace}
                onChange={(e) => handleChange('tipo_bolo', 'enlace', e.target.value)}
                sx={{ ...textFieldStyle, mb: 2 }}
              />
            </Grid>

            {/* Sección: Información del Proveedor */}

          </Grid>
          <Button
            type="submit"
            variant="contained"
            disabled={loading}
            sx={{
              mt: 4,
              backgroundColor: '#7C3AED',
              '&:hover': { backgroundColor: '#6D28D9' },
              px: 4,
              py: 1.5,
            }}
          >
            {loading ? <CircularProgress size={24} /> : 'Guardar Actuación'}
          </Button>
        </form>
      </Container>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
      <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={() => setSuccessMessage('')}>
        <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default AgregarActuacion;
