import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  TextField,
  Select,
  MenuItem,
  Container,
  Pagination,
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Search as SearchIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';
import { useAuth } from '../../context/AuthContext';
import { getFirestore, collection, getDocs, addDoc, doc, getDoc } from 'firebase/firestore';
import AgregarContacto from './AgregarContacto';
import { Link } from 'react-router-dom';

function Contactos() {
  const [contactos, setContactos] = useState([]);
  const [openAgregarContacto, setOpenAgregarContacto] = useState(false);
  const { currentOrganization } = useAuth();
  const db = getFirestore();
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('Todo');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (currentOrganization) {
      fetchContactos();
    }
  }, [currentOrganization]);

  const fetchContactos = async () => {
    if (!currentOrganization) return;

    try {
      const contactosRef = collection(db, 'organizaciones', currentOrganization.organizacion_id, 'contactos');
      const snapshot = await getDocs(contactosRef);

      const contactosPromises = snapshot.docs.map(async (docSnapshot) => {
        const contactoData = docSnapshot.data();

        if (contactoData.tipo === 'Empresa' && contactoData.organizacion_id) {
          try {
            const empresaDocRef = doc(db, 'organizaciones', contactoData.organizacion_id);
            const empresaDoc = await getDoc(empresaDocRef);

            if (empresaDoc.exists()) {
              const empresaData = empresaDoc.data();
              return {
                id: docSnapshot.id,
                ...contactoData,
                nombre: empresaData.nombre || 'Nombre no disponible',
                email: empresaData.email || 'Email no disponible',
                telefono: empresaData.telefono || 'Teléfono no disponible',
                organizacion_id: contactoData.organizacion_id // Aseguramos que se incluya el organizacion_id
              };
            } else {
              return {
                id: docSnapshot.id,
                ...contactoData,
                nombre: 'Empresa no encontrada',
                email: 'N/A',
                telefono: 'N/A'
              };
            }
          } catch (empresaError) {
            return {
              id: docSnapshot.id,
              ...contactoData,
              nombre: 'Error al cargar empresa',
              email: 'N/A',
              telefono: 'N/A'
            };
          }
        } else {
          return {
            id: docSnapshot.id,
            ...contactoData,
            nombre: contactoData.nombre || 'Nombre no disponible',
            email: contactoData.email || 'Email no disponible',
            telefono: contactoData.telefono || 'Teléfono no disponible'
          };
        }
      });

      const contactosList = await Promise.all(contactosPromises);
      setContactos(contactosList);
    } catch (error) {
      console.error('Error al cargar contactos:', error);
    }
  };

  const handleAgregarContacto = async (nuevoContacto) => {
    if (!currentOrganization) return;

    try {
      console.log('Recibiendo nuevo contacto:', nuevoContacto);

      const contactosRef = collection(db, 'organizaciones', currentOrganization.organizacion_id, 'contactos');

      const contactoParaAgregar = {
        tipo: nuevoContacto.tipo,
        nombre: nuevoContacto.nombre,
        telefono: nuevoContacto.telefono,
        pais: nuevoContacto.pais,
        provincia: nuevoContacto.provincia,
        ciudad: nuevoContacto.ciudad,
        direccion: nuevoContacto.direccion,
      };

      console.log('Contacto para agregar:', contactoParaAgregar);

      if (nuevoContacto.organizacion_id) {
        contactoParaAgregar.organizacion_id = nuevoContacto.organizacion_id;
        contactoParaAgregar.empresaNombre = nuevoContacto.empresaNombre;
      }

      if (nuevoContacto.dni !== undefined) {
        contactoParaAgregar.dni = nuevoContacto.dni;
      }
      if (nuevoContacto.email !== undefined) {
        contactoParaAgregar.email = nuevoContacto.email
      }

      await addDoc(contactosRef, contactoParaAgregar);

      setOpenAgregarContacto(false);
      fetchContactos();
    } catch (error) {
      console.error("Error al agregar contacto:", error);
    }
  };

  const filteredContactos = contactos
    .filter(contacto => {
      const matchesSearch =
        (contacto.nombre && contacto.nombre.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (contacto.email && contacto.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (contacto.telefono && contacto.telefono.toLowerCase().includes(searchTerm.toLowerCase()));
      const matchesFilter = filterType === 'Todo' || contacto.tipo === filterType;
      return matchesSearch && matchesFilter;
    });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const paginatedContactos = filteredContactos.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  return (
    <Box sx={{
      backgroundColor: '#000000',
      minHeight: '100vh',
      padding: '24px'
    }}>
      <Container maxWidth="xl">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#FFFFFF' }}>Contactos</Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpenAgregarContacto(true)}
            sx={{
              backgroundColor: '#7C3AED',
              '&:hover': { backgroundColor: '#6D28D9' },
              borderRadius: '8px',
              textTransform: 'none',
              fontWeight: 'bold',
              px: 3,
              py: 1.5
            }}
          >
            Agregar Contacto
          </Button>
        </Box>

        <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
          <Select
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
            size="small"
            sx={{
              minWidth: 120,
              backgroundColor: '#2D3748',
              borderRadius: '8px',
              '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
              '& .MuiSelect-select': { color: '#FFFFFF' }
            }}
          >
            <MenuItem value="Todo">Todo</MenuItem>
            <MenuItem value="Persona">Persona</MenuItem>
            <MenuItem value="Empresa">Empresa</MenuItem>
          </Select>
          <TextField
            placeholder="Buscar contacto"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              flexGrow: 1,
              backgroundColor: '#2D3748',
              borderRadius: '8px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': { border: 'none' },
              },
              '& .MuiInputBase-input': { color: '#FFFFFF' }
            }}
            InputProps={{
              startAdornment: <SearchIcon sx={{ color: '#A0AEC0', mr: 1 }} />,
            }}
          />
        </Box>

        <TableContainer
          component={Paper}
          sx={{
            backgroundColor: '#1E1E1E',
            borderRadius: '16px',
            overflow: 'hidden',
            boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
          }}
        >
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#2D3748' }}>
                <TableCell padding="checkbox" sx={{ borderBottom: 'none' }}>
                  <input type="checkbox" style={{ accentColor: '#7C3AED' }} />
                </TableCell>
                <TableCell sx={{ color: '#A0AEC0', fontWeight: 'bold', borderBottom: 'none' }}>NOMBRE</TableCell>
                <TableCell sx={{ color: '#A0AEC0', fontWeight: 'bold', borderBottom: 'none' }}>TELÉFONO</TableCell>
                <TableCell sx={{ color: '#A0AEC0', fontWeight: 'bold', borderBottom: 'none' }}>EMAIL</TableCell>
                <TableCell sx={{ color: '#A0AEC0', fontWeight: 'bold', borderBottom: 'none' }}>TIPO</TableCell>
                <TableCell sx={{ color: '#A0AEC0', fontWeight: 'bold', borderBottom: 'none' }}>NOMBRE DE LA EMPRESA</TableCell>
                <TableCell sx={{ borderBottom: 'none' }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedContactos.map((contacto) => (
                <TableRow
                  key={contacto.id}
                  component={Link}
                  to={`/contactos/${contacto.id}`}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '&:hover': { backgroundColor: 'rgba(124, 58, 237, 0.1)' },
                  }}
                >
                  <TableCell padding="checkbox">
                    <input type="checkbox" style={{ accentColor: '#7C3AED' }} />
                  </TableCell>
                  <TableCell sx={{ color: '#E2E8F0' }}>{contacto.nombre}</TableCell>
                  <TableCell sx={{ color: '#E2E8F0' }}>{contacto.telefono}</TableCell>
                  <TableCell sx={{ color: '#E2E8F0' }}>{contacto.email}</TableCell>
                  <TableCell sx={{ color: '#E2E8F0' }}>{contacto.tipo}</TableCell>
                  <TableCell sx={{ color: '#E2E8F0' }}>
                    {contacto.tipo === 'Empresa' ? contacto.nombre : (contacto.empresaNombre || '-')}
                  </TableCell>
                  <TableCell>
                    <IconButton size="small" sx={{ color: '#A0AEC0' }}>
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2
          }}>
            <Typography
              sx={{
                color: '#A0AEC0',
                fontSize: '0.875rem'
              }}
            >
              Filas por página:
            </Typography>
            <Select
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              size="small"
              sx={{
                minWidth: 80,
                backgroundColor: '#2D3748',
                borderRadius: '8px',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none'
                },
                '& .MuiSelect-select': {
                  color: '#FFFFFF',
                  paddingY: '8px'
                }
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </Box>
          <Pagination
            count={Math.ceil(filteredContactos.length / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
            color="primary"
            sx={{
              '& .MuiPaginationItem-root': {
                color: '#FFFFFF',
              },
            }}
          />
        </Box>
        <AgregarContacto
          open={openAgregarContacto}
          onClose={() => setOpenAgregarContacto(false)}
          onAgregar={handleAgregarContacto}
          contactosExistentes={contactos}  // Añade esta línea
        />
      </Container>
    </Box>
  );
}

export default Contactos;
