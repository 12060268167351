import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Chip,
  Container,
  Menu,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
  Snackbar,
  Pagination,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Add as AddIcon, MoreVert as MoreVertIcon, Search as SearchIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';

function Actuaciones() {
  const navigate = useNavigate();
  const { user, currentOrganization, events, fetchEvents } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('Todo');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (currentOrganization) {
      console.log("Organización actual cambiada:", currentOrganization);
      fetchEvents();
    }
  }, [currentOrganization, fetchEvents]);

  useEffect(() => {
    console.log("Eventos actualizados:", events);
  }, [events]);


  const getStatusStyle = (estado) => {
    if (!estado) {
      return { color: '#1F2937', backgroundColor: '#F3F4F6', borderColor: '#E5E7EB' };
    }

    switch (estado.toLowerCase()) {
      case 'cancelado':
        return { color: '#FFFFFF', backgroundColor: '#DC2626', borderColor: '#DC2626' };
      case 'inactivo':
        return { color: '#4B5563', backgroundColor: '#E5E7EB', borderColor: '#D1D5DB' };
      case 'pendiente':
        return { color: '#92400E', backgroundColor: '#FEF3C7', borderColor: '#FBBF24' };
      case 'confirmado':
        return { color: '#065F46', backgroundColor: '#D1FAE5', borderColor: '#34D399' };
      case 'finalizado':
        return { color: '#FFFFFF', backgroundColor: '#3B82F6', borderColor: '#2563EB' };
      default:
        return { color: '#1F2937', backgroundColor: '#F3F4F6', borderColor: '#E5E7EB' };
    }
  };

  const formatDate = (date) => {
    if (!date) return 'Fecha no disponible';

    // Si date es un objeto Timestamp de Firestore
    if (date && typeof date.toDate === 'function') {
      date = date.toDate();
    }

    // Si date es un string, intentamos convertirlo a Date
    if (typeof date === 'string') {
      date = new Date(date);
    }

    // Verificamos si la fecha es válida
    if (!(date instanceof Date) || isNaN(date)) {
      return 'Fecha inválida';
    }


    return new Intl.DateTimeFormat('es-ES', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'Europe/Madrid', // Asegura que se use la zona horaria correcta
      hour12: false, // Configura el formato de 24 horas
    }).format(date);
  };

  const handleMenuClick = (event, eventItem) => {
    event.preventDefault(); // Previene la navegación
    event.stopPropagation(); // Evita que el evento se propague al TableRow
    setAnchorEl(event.currentTarget);
    setSelectedEvent(eventItem);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    handleMenuClose();
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = async () => {
    if (selectedEvent && currentOrganization) {
      const db = getFirestore();
      try {
        const eventRef = doc(db, 'organizaciones', currentOrganization.organizacion_id, 'eventos', selectedEvent.id);
        await updateDoc(eventRef, { eliminado: true });
        fetchEvents();
        setSnackbar({ open: true, message: 'Evento marcado como eliminado', severity: 'success' });
      } catch (error) {
        setSnackbar({ open: true, message: `Error al marcar el evento como eliminado: ${error.message}`, severity: 'error' });
      }
    } else {
      setSnackbar({ open: true, message: 'No se pudo marcar como eliminado: evento o organización no seleccionados', severity: 'error' });
    }
    setOpenConfirmDialog(false);
    setSelectedEvent(null);
  };

  const filteredEvents = events
    ? events
      .filter(event => event && !event.eliminado)
      .filter(event => {
        const matchesSearch =
          (event.nombre && event.nombre.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (event.cliente && event.cliente.nombre && event.cliente.nombre.toLowerCase().includes(searchTerm.toLowerCase()));
        const matchesFilter = filterStatus === 'Todo' ||
          (event.estado && event.estado.toLowerCase() === filterStatus.toLowerCase());
        return matchesSearch && matchesFilter;
      })
    : [];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const paginatedEvents = filteredEvents.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  return (
    <Box sx={{
      backgroundColor: '#000000',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Box sx={{
        backgroundColor: '#000000',
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px',
        flexGrow: 1,
        mt: 2,
        overflow: 'hidden',
      }}>
        <Container maxWidth="xl" sx={{ pt: 4, pb: 4 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#FFFFFF' }}>
              Actuaciones
            </Typography>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => navigate('/actuaciones/agregar')}
              sx={{
                backgroundColor: '#7C3AED',
                '&:hover': { backgroundColor: '#6D28D9' },
                borderRadius: '8px',
                textTransform: 'none',
                fontWeight: 'bold',
                px: 3,
                py: 1.5
              }}
            >
              Crear Actuación
            </Button>
          </Box>

          <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
            <Select
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              size="small"
              sx={{
                minWidth: 120,
                backgroundColor: '#2D3748',
                borderRadius: '8px',
                '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                '& .MuiSelect-select': { color: '#FFFFFF' }
              }}
            >
              <MenuItem value="todo">Todo</MenuItem>
              <MenuItem value="confirmado">Confirmado</MenuItem>
              <MenuItem value="pendiente">Pendiente</MenuItem>
              <MenuItem value="inactivo">Inactivo</MenuItem>
              <MenuItem value="cancelado">Cancelado</MenuItem>
              <MenuItem value="finalizado">Finalizado</MenuItem>
            </Select>

            <TextField
              placeholder="Buscar actuación"
              size="small"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{
                flexGrow: 1,
                backgroundColor: '#2D3748',
                borderRadius: '8px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { border: 'none' },
                },
                '& .MuiInputBase-input': { color: '#FFFFFF' }
              }}
              InputProps={{
                startAdornment: <SearchIcon sx={{ color: '#A0AEC0', mr: 1 }} />,
              }}
            />
          </Box>

          <TableContainer
            component={Paper}
            sx={{
              backgroundColor: '#1E1E1E',
              borderRadius: '16px',
              overflow: 'hidden',
              boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
            }}
          >
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: '#2D3748' }}>
                  <TableCell padding="checkbox" sx={{ borderBottom: 'none' }}>
                    <input type="checkbox" style={{ accentColor: '#7C3AED' }} />
                  </TableCell>
                  <TableCell sx={{ color: '#A0AEC0', fontWeight: 'bold', borderBottom: 'none' }}>NOMBRE DE LA ACTUACIÓN</TableCell>
                  <TableCell sx={{ color: '#A0AEC0', fontWeight: 'bold', borderBottom: 'none' }}>FECHA Y HORA</TableCell>
                  <TableCell sx={{ color: '#A0AEC0', fontWeight: 'bold', borderBottom: 'none' }}>ESTADO</TableCell>
                  <TableCell sx={{ color: '#A0AEC0', fontWeight: 'bold', borderBottom: 'none' }}>DIRECCIÓN</TableCell>
                  <TableCell sx={{ color: '#A0AEC0', fontWeight: 'bold', borderBottom: 'none' }}>CLIENTE</TableCell>
                  <TableCell sx={{ borderBottom: 'none' }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedEvents.map((event) => (
                  <TableRow
                    key={event.id}
                    component={Link}
                    to={`/actuaciones/${event.id}`}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      '&:hover': { backgroundColor: 'rgba(124, 58, 237, 0.1)' },
                      textDecoration: 'none'
                    }}
                  >
                    <TableCell padding="checkbox">
                      <input type="checkbox" style={{ accentColor: '#7C3AED' }} />
                    </TableCell>
                    <TableCell sx={{ color: '#E2E8F0' }}>{event.nombre || 'N/A'}</TableCell>
                    <TableCell sx={{ color: '#E2E8F0' }}>{formatDate(event.inicio)}</TableCell>
                    <TableCell>
                      <Chip
                        label={event.estado || 'N/A'}
                        size="small"
                        sx={{
                          borderRadius: '4px',
                          fontWeight: 'medium',
                          ...getStatusStyle(event.estado)
                        }}
                      />
                    </TableCell>
                    <TableCell sx={{ color: '#E2E8F0' }}>{event.direccion || 'N/A'}</TableCell>
                    <TableCell sx={{ color: '#E2E8F0' }}>
                      {event.cliente && event.cliente.nombre ? event.cliente.nombre : 'N/A'}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        size="small"
                        sx={{ color: '#A0AEC0' }}
                        onClick={(e) => handleMenuClick(e, event)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2  // Añade espacio entre los elementos
            }}>
              <Typography
                sx={{
                  color: '#A0AEC0',
                  fontSize: '0.875rem'
                }}
              >
                Filas por página:
              </Typography>
              <Select
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}
                size="small"
                sx={{
                  minWidth: 80,
                  backgroundColor: '#2D3748',
                  borderRadius: '8px',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none'
                  },
                  '& .MuiSelect-select': {
                    color: '#FFFFFF',
                    paddingY: '8px'
                  }
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </Box>
            <Pagination
              count={Math.ceil(filteredEvents.length / rowsPerPage)}
              page={page}
              onChange={handleChangePage}
              color="primary"
              sx={{
                '& .MuiPaginationItem-root': {
                  color: '#FFFFFF',
                },
              }}
            />
          </Box>
        </Container>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleDeleteClick}>
          <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
          Eliminar
        </MenuItem>
      </Menu>

      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle>Confirmar eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que quieres eliminar esta actuación? Esta acción no se puede deshacer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)}>Cancelar</Button>
          <Button onClick={handleConfirmDelete} color="error">Eliminar</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Actuaciones;
