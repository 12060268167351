import React, { createContext, useContext, useState, useEffect } from 'react';
import { signOut, onAuthStateChanged, setPersistence, browserLocalPersistence } from 'firebase/auth'; // Importa onAuthStateChanged
import { auth, getUserOrganizations, getUserData } from '../services/firebase'; // Asegúrate de importar la instancia de auth
import { getFirestore, collection, getDocs, query, where, Timestamp, doc, getDoc } from 'firebase/firestore';


const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Estado para manejar la carga inicial
  const [currentOrganization, setCurrentOrganization] = useState(null);
  const [events, setEvents] = useState([]);

  const fetchEvents = async () => {
    if (!user || !currentOrganization) {
      console.log("Usuario o organización no disponible para obtener eventos");
      setEvents([]);
      return;
    }

    const db = getFirestore();
    const organizacionRef = doc(db, 'organizaciones', currentOrganization.organizacion_id);
    const eventsRef = collection(organizacionRef, 'eventos');

    try {
      const querySnapshot = await getDocs(eventsRef);
      const now = new Date();

      let eventsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        inicio: doc.data().inicio instanceof Timestamp ? doc.data().inicio.toDate() : doc.data().inicio,
      }));

      // Filtrar y ordenar eventos
      eventsList = eventsList.filter(event => {
        const eventDate = new Date(event.inicio);
        return eventDate >= now || event.estado?.toLowerCase() === 'pendiente';
      }).filter(event => event.clase === 'bolo' || event.clase === 'bolo_manual');

      eventsList.sort((a, b) => a.inicio - b.inicio);
      console.log("Eventos obtenidos:", eventsList);
      setEvents(eventsList);
    } catch (error) {
      console.error("Error al obtener eventos:", error);
      setEvents([]);
    }
  };

  const login = (userData) => {
    setUser(userData);
    if (userData.organizations && userData.organizations.length > 0) {
      setCurrentOrganization(userData.organizations[0]);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setCurrentOrganization(null);
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  const changeOrganization = (org) => {
    setCurrentOrganization(org);
  };

  const value = {
    user,
    currentOrganization,
    setCurrentOrganization: changeOrganization,
    login,
    logout,
    events,
    fetchEvents,
    loading, // Agregar el estado de carga al contexto
    user,
  };

  // Verifica el estado de autenticación al cargar la aplicación
  useEffect(() => {
    // Configura la persistencia
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
          if (currentUser) {
            // Establece el usuario actual
            setUser(currentUser);

            try {
              const userData = await getUserData(currentUser.phoneNumber);

              if (userData) {
                const organizations = await getUserOrganizations(userData.id);
                login({ ...userData, organizations });
              } else {
                console.error('Usuario no encontrado en Firestore. Firebase User:', currentUser);
              }
            } catch (error) {
              console.error("Error al obtener las organizaciones del usuario:", error);
              setCurrentOrganization(null);
            }
          } else {
            // Restablece el estado si no hay usuario autenticado
            setUser(null);
            setCurrentOrganization(null);
          }
          setLoading(false); // Finaliza la carga solo después de completar la verificación
        });

        return () => unsubscribe(); // Limpia el listener al desmontar
      })
      .catch((error) => {
        console.error("Error al configurar persistencia de Firebase:", error);
      });
  }, []);


  useEffect(() => {
    if (user && currentOrganization) {
      fetchEvents();
    }
  }, [user, currentOrganization]);

  return (
    <AuthContext.Provider value={value}>
      {loading ? (
        // Mostrar un spinner o mensaje de carga
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <p>Cargando...</p>
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>);
}

export function useAuth() {
  return useContext(AuthContext);
}
