import React, { useState, useEffect } from 'react';
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, updateDoc, getDocs, collection } from 'firebase/firestore';
import { Box, Card, CardContent, Typography, Button, Grid, IconButton, Chip, TextField, Stack } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import { useAuth } from '../../context/AuthContext';

function ContactDetails() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [contact, setContact] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [editedContact, setEditedContact] = useState({});
    const { currentOrganization } = useAuth();
    const [paises, setPaises] = useState([]);
    const [provincias, setProvincias] = useState([]);
    const [ciudades, setCiudades] = useState([]);
    const [empresasDisponibles, setEmpresasDisponibles] = useState([]); // Para cargar las empresas disponibles


    const db = getFirestore();

    const handleChange = (e, fieldName, fieldValue) => {
        const name = fieldName || e.target.name;  // Usa el parámetro fieldName si está definido, si no toma e.target.name
        const value = fieldValue || e.target.value;  // Usa el parámetro fieldValue si está definido, si no toma e.target.value

        setEditedContact(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const fetchPaises = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'paises'));
            const paisesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            paisesList.sort((a, b) => a.nombre_es.localeCompare(b.nombre_es));
            setPaises(paisesList);
        } catch (error) {
            console.error('Error fetching paises:', error);
        }
    };

    const fetchProvincias = async (paisId) => {
        try {

            const provincesCollection = collection(db, `paises/${paisId}/provinces`);
            const querySnapshot = await getDocs(provincesCollection);
            const provinciasList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            provinciasList.sort((a, b) => a.name_es.localeCompare(b.name_es));
            setProvincias(provinciasList);
        } catch (error) {
            console.error('Error fetching provincias:', error);
        }
    };

    const fetchCiudades = async (paisId, provinciaId) => {
        try {
            const citiesCollection = collection(db, `paises/${paisId}/provinces/${provinciaId}/localities`);
            const querySnapshot = await getDocs(citiesCollection);
            const ciudadesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            ciudadesList.sort((a, b) => a.name_es.localeCompare(b.name_es));
            setCiudades(ciudadesList);
        } catch (error) {
            console.error('Error fetching ciudades:', error);
        }
    };

    const handleCountryChange = async (paisNombre) => {
        const selectedPais = paises.find(pais => pais.nombre_es === paisNombre);
        if (selectedPais) {
            // Actualizar el campo `pais` y limpiar los campos dependientes
            setEditedContact(prev => ({
                ...prev,
                pais: paisNombre,
                provincia: '', // Resetear provincia
                ciudad: '', // Resetear ciudad
            }));

            setProvincias([]); // Limpia las provincias
            setCiudades([]); // Limpia las ciudades

            await fetchProvincias(selectedPais.id); // Cargar nuevas provincias
        }
    };

    const handleProvinceChange = async (provinciaNombre) => {
        const selectedPais = paises.find(pais => pais.nombre_es === editedContact.pais);
        const selectedProvincia = provincias.find(provincia => provincia.name_es === provinciaNombre);
        if (selectedPais && selectedProvincia) {
            // Actualizar el campo `provincia` y limpiar las ciudades dependientes
            setEditedContact(prev => ({
                ...prev,
                provincia: provinciaNombre,
                ciudad: '', // Resetear ciudad
            }));

            setCiudades([]); // Limpia las ciudades

            await fetchCiudades(selectedPais.id, selectedProvincia.id); // Cargar nuevas ciudades
        }
    };


    useEffect(() => {
        const fetchContact = async () => {
            const db = getFirestore();
            const contactRef = doc(db, 'organizaciones', currentOrganization.organizacion_id, 'contactos', id);
            try {
                const docSnap = await getDoc(contactRef);
                if (docSnap.exists()) {
                    const contactData = docSnap.data();
                    setContact(contactData);
                    setEditedContact(contactData);

                    await fetchPaises();// Carga todos los países
                    if (contactData.pais) {
                        const selectedPais = contactData.pais;
                        const paisEncontrado = (await getDocs(collection(db, 'paises')))
                            .docs.find(pais => pais.data().nombre_es === selectedPais);
                        if (paisEncontrado) await fetchProvincias(paisEncontrado.id);
                    }

                    if (contactData.pais && contactData.provincia) {
                        const selectedProvincia = contactData.provincia;
                        const paisId = (await getDocs(collection(db, 'paises')))
                            .docs.find(pais => pais.data().nombre_es === contactData.pais)?.id;
                        const provinciaId = (await getDocs(collection(db, `paises/${paisId}/provinces`)))
                            .docs.find(provincia => provincia.data().name_es === selectedProvincia)?.id;

                        if (paisId && provinciaId) await fetchCiudades(paisId, provinciaId);
                    }
                    await fetchEmpresas();
                } else {
                    console.error('No se encontró el contacto');
                }



            } catch (error) {
                console.error('Error al obtener el contacto:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchContact();
    }, [id, currentOrganization]);

    const handleEditToggle = () => {
        setIsEditing(!isEditing);
    };

    const handleInputChange = (field, value) => {
        setEditedContact((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const fetchEmpresas = async () => {
        try {
            const querySnapshot = await getDocs(
                collection(db, 'organizaciones', currentOrganization.organizacion_id, 'contactos')
            );
            const empresas = querySnapshot.docs
                .map(doc => ({ id: doc.id, ...doc.data() }))
                .filter(contacto => contacto.tipo === 'Empresa'); // Filtrar solo empresas
            setEmpresasDisponibles(empresas);
        } catch (error) {
            console.error('Error fetching empresas:', error);
        }
    };

    const textFieldStyle = {
        mb: 2,
        '& .MuiInputBase-input': {
            color: '#FFFFFF', // Color del texto
        },
        '& .MuiInputLabel-root': {
            color: '#A0AEC0', // Color de la etiqueta
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#FFFFFF', // Color del borde
            },
            '&:hover fieldset': {
                borderColor: '#7C3AED', // Color del borde al pasar el mouse
            },
            '&.Mui-focused fieldset': {
                borderColor: '#7C3AED', // Color del borde al enfocar
            },
        },
    };

    const handleSaveChanges = async () => {
        try {
            const db = getFirestore();
            const contactRef = doc(db, 'organizaciones', currentOrganization.organizacion_id, 'contactos', id);

            // Actualiza los datos en Firebase
            await updateDoc(contactRef, editedContact);

            // Actualiza el estado local
            setContact(editedContact);
            setIsEditing(false);
        } catch (error) {
            console.error('Error al guardar los cambios:', error);
        }
    };

    const handleCancelEdit = () => {
        setEditedContact(contact); // Reestablece los datos originales
        setIsEditing(false);
    };

    if (loading) {
        return (
            <Box sx={{ backgroundColor: '#000000', color: '#FFFFFF', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography>Cargando...</Typography>
            </Box>
        );
    }

    if (!contact) {
        return (
            <Box sx={{ backgroundColor: '#000000', color: '#FFFFFF', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography>No se encontró el contacto.</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ backgroundColor: '#000000', minHeight: '100vh', py: 4 }}>
            <Box sx={{ maxWidth: 900, margin: '0 auto', px: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                    <IconButton
                        onClick={() => navigate('/contactos')}
                        sx={{ color: '#7C3AED', mr: 2 }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h4" sx={{ color: '#FFFFFF', flexGrow: 1 }}>
                        {isEditing ? 'Editar Contacto' : 'Detalles del Contacto'}
                    </Typography>
                    <Chip
                        label={contact.tipo}
                        sx={{
                            backgroundColor: contact.tipo === 'Empresa' ? '#7C3AED' : '#3B82F6',
                            color: '#FFFFFF',
                            fontWeight: 'bold',
                        }}
                    />
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Card
                            sx={{
                                backgroundColor: '#1E1E1E',
                                color: '#FFFFFF',
                                borderRadius: 2,
                                boxShadow: 3,
                            }}
                        >
                            <CardContent>
                                <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                    <PersonIcon sx={{ mr: 1, color: '#7C3AED' }} />
                                    Información General
                                </Typography>
                                {isEditing ? (
                                    <>
                                        <TextField
                                            label="Nombre"
                                            value={editedContact.nombre || ''}
                                            onChange={(e) => handleInputChange('nombre', e.target.value)}
                                            fullWidth
                                            sx={{ ...textFieldStyle, mb: 2 }}
                                        />
                                        <TextField
                                            label="Teléfono"
                                            value={editedContact.telefono || ''}
                                            onChange={(e) => handleInputChange('telefono', e.target.value)}
                                            fullWidth
                                            sx={{ ...textFieldStyle, mb: 2 }}
                                        />
                                        <TextField
                                            label="Email"
                                            value={editedContact.email || ''}
                                            onChange={(e) => handleInputChange('email', e.target.value)}
                                            fullWidth
                                            sx={{ ...textFieldStyle, mb: 2 }}
                                        />
                                        {contact.tipo === 'Persona' && (
                                            <FormControl fullWidth sx={{ ...textFieldStyle, mb: 2 }}>
                                                <InputLabel>Empresa Asociada</InputLabel>
                                                <Select
                                                    value={editedContact.contactoEmpresaId || ''}
                                                    onChange={(e) => {
                                                        const empresaSeleccionada = empresasDisponibles.find(
                                                            empresa => empresa.id === e.target.value
                                                        );
                                                        handleChange(e, 'contactoEmpresaId', e.target.value);
                                                        handleChange(e, 'empresaNombre', empresaSeleccionada?.nombre || '');
                                                    }}
                                                >
                                                    <MenuItem value="">Ninguna</MenuItem>
                                                    {empresasDisponibles.map(empresa => (
                                                        <MenuItem key={empresa.id} value={empresa.id}>
                                                            {empresa.nombre}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <Typography><strong>Nombre:</strong> {contact.nombre || 'N/A'}</Typography>
                                        <Typography><strong>Teléfono:</strong> {contact.telefono || 'N/A'}</Typography>
                                        <Typography><strong>Email:</strong> {contact.email || 'N/A'}</Typography>
                                        {contact.tipo === 'Persona' && contact.empresaNombre && (
                                            <Typography><strong>Empresa Asociada:</strong> {contact.empresaNombre}</Typography>
                                        )}
                                    </>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card
                            sx={{
                                backgroundColor: '#1E1E1E',
                                color: '#FFFFFF',
                                borderRadius: 2,
                                boxShadow: 3,
                            }}
                        >
                            <CardContent>
                                <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                    <LocationOnIcon sx={{ mr: 1, color: '#7C3AED' }} />
                                    Ubicación
                                </Typography>
                                {isEditing ? (
                                    <>
                                        <FormControl fullWidth sx={{ ...textFieldStyle, mb: 2 }} required>
                                            <InputLabel id="pais-label">País</InputLabel>
                                            <Select
                                                labelId="pais-label"
                                                value={editedContact.pais}
                                                label="País"
                                                onChange={(e) => handleCountryChange(e.target.value)}
                                            >
                                                {paises.map((pais) => (
                                                    <MenuItem key={pais.id} value={pais.nombre_es}>
                                                        {pais.nombre_es}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl fullWidth sx={{ ...textFieldStyle, mb: 2 }} required>
                                            <InputLabel id="provincia-label">Provincia</InputLabel>
                                            <Select
                                                labelId="provincia-label"
                                                value={editedContact.provincia}
                                                label="Provincia"
                                                onChange={(e) => handleProvinceChange(e.target.value)}
                                            >
                                                {provincias.map((provincia) => (
                                                    <MenuItem key={provincia.id} value={provincia.name_es}>
                                                        {provincia.name_es}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl fullWidth sx={{ ...textFieldStyle, mb: 2 }} required>
                                            <InputLabel id="ciudad-label">Ciudad</InputLabel>
                                            <Select
                                                labelId="ciudad-label"
                                                value={editedContact.ciudad}
                                                label="Ciudad"
                                                onChange={(e) => handleChange(e, 'ciudad', e.target.value)}
                                            >
                                                {ciudades.map((ciudad) => (
                                                    <MenuItem key={ciudad.id} value={ciudad.name_es}>
                                                        {ciudad.name_es}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            label="Dirección"
                                            value={editedContact.direccion || ''}
                                            onChange={(e) => handleInputChange('direccion', e.target.value)}
                                            fullWidth
                                            sx={{ ...textFieldStyle, mb: 2 }}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Typography><strong>País:</strong> {contact.pais || 'N/A'}</Typography>
                                        <Typography><strong>Provincia:</strong> {contact.provincia || 'N/A'}</Typography>
                                        <Typography><strong>Ciudad:</strong> {contact.ciudad || 'N/A'}</Typography>
                                        <Typography><strong>Dirección:</strong> {contact.direccion || 'N/A'}</Typography>
                                    </>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Stack direction="row" spacing={2} justifyContent="center" sx={{ mt: 4 }}>
                    {isEditing ? (
                        <>
                            <Button variant="contained" onClick={handleSaveChanges} sx={{ backgroundColor: '#7C3AED' }}>
                                Guardar
                            </Button>
                            <Button variant="outlined" onClick={handleCancelEdit} sx={{ color: '#7C3AED', borderColor: '#7C3AED' }}>
                                Cancelar
                            </Button>
                        </>
                    ) : (
                        <Button variant="contained" onClick={handleEditToggle} sx={{ backgroundColor: '#7C3AED' }}>
                            Editar
                        </Button>
                    )}
                </Stack>
            </Box>
        </Box>
    );
}

export default ContactDetails;
