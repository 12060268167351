// Muy importante: ahora las reglas de firebase para ver documentos y demás no piden autorización porque el código es de pruebas y no funcinaba el login, NECESARIO CORREGIR ESTO


import React, { useEffect, useState, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import {
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
  Chip,
  Link as MuiLink,
  Container,
  IconButton,
  Stack,
  TextField,
  Button,
  Menu,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  InputLabel,
  FormControl,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@mui/material';
import { getFirestore, doc, getDoc, updateDoc, collection, getDocs, setDoc, deleteDoc } from 'firebase/firestore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PersonIcon from '@mui/icons-material/Person';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BusinessIcon from '@mui/icons-material/Business';
import EditIcon from '@mui/icons-material/Edit';
import DescriptionIcon from '@mui/icons-material/Description';
import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { uploadDocument, updateActuacionDocuments, markDocumentAsDeleted } from '../../services/documentServices';
import CloseIcon from '@mui/icons-material/Close';
import GroupIcon from '@mui/icons-material/Group';
import Autocomplete from '@mui/material/Autocomplete';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import ImageIcon from '@mui/icons-material/Image';
import UploadIcon from '@mui/icons-material/Upload';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { format, parseISO, addMinutes } from 'date-fns';
import { is } from 'date-fns/locale';


dayjs.extend(customParseFormat);


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#7C3AED', // Color principal (morado)
    },
    background: {
      default: '#1E1E1E', // Fondo oscuro
      paper: '#1E1E1E',
    },
    text: {
      primary: '#FFFFFF', // Texto blanco
      secondary: '#9CA3AF', // Texto secundario gris
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            color: '#FFFFFF', // Color del texto del input
          },
          '& .MuiInputLabel-root': {
            color: '#9CA3AF', // Color del label
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': { borderColor: '#FFFFFF' }, // Borde blanco
            '&:hover fieldset': { borderColor: '#7C3AED' }, // Borde al hover
            '&.Mui-focused fieldset': { borderColor: '#7C3AED' }, // Borde al enfocar
          },
        },
      },
    },
    MuiDateTimePicker: {
      styleOverrides: {
        root: {
          '& .MuiPickersDay-root': {
            color: '#FFFFFF', // Color del texto en el calendario
            '&.Mui-selected': {
              backgroundColor: '#7C3AED', // Fondo seleccionado
            },
            '&.Mui-selected:hover': {
              backgroundColor: '#6D28D9', // Hover en día seleccionado
            },
          },
          '& .MuiPickersYear-root': {
            color: '#FFFFFF', // Texto de los años
          },
          '& .MuiPickersMonth-root': {
            color: '#FFFFFF', // Texto de los meses
          },
          width: '100%',
        },
      },
    },
  },
});




function DocumentDialog({ open, onClose, newDocument, handleDocumentChange, handleAddDocument, tiposDocumento }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: '#1E1E1E',
          color: '#FFFFFF',
          borderRadius: '12px',
        }
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h6" component="div" sx={{ color: '#7C3AED', fontWeight: 'bold' }}>
          Añadir Documento
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: '#7C3AED',
            '&:hover': {
              backgroundColor: 'rgba(124, 58, 237, 0.08)',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ borderColor: 'rgba(255, 255, 255, 0.12)' }}>
        <Box component="form" sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
          <TextField
            autoFocus
            margin="dense"
            name="nombre"
            label="Nombre del documento"
            type="text"
            fullWidth
            variant="outlined"
            value={newDocument.nombre}
            onChange={handleDocumentChange}
            InputLabelProps={{ style: { color: '#9CA3AF' } }}
            InputProps={{ style: { color: '#FFFFFF' } }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.23)' },
                '&:hover fieldset': { borderColor: '#7C3AED' },
                '&.Mui-focused fieldset': { borderColor: '#7C3AED' },
              },
            }}
          />
          <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
            <InputLabel id="tipo-documento-label" sx={{ color: '#9CA3AF' }}>Tipo de documento</InputLabel>
            <Select
              labelId="tipo-documento-label"
              name="tipo"
              value={newDocument.tipo}
              onChange={handleDocumentChange}
              label="Tipo de documento"
              sx={{
                color: '#FFFFFF',
                '& .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(255, 255, 255, 0.23)' },
                '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#7C3AED' },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#7C3AED' },
              }}
            >
              {tiposDocumento.map((tipo) => (
                <MenuItem key={tipo} value={tipo}>{tipo}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
            <Button
              component="label"
              variant="outlined"
              startIcon={<FileUploadIcon />}
              sx={{
                color: '#7C3AED',
                borderColor: '#7C3AED',
                '&:hover': {
                  borderColor: '#6D28D9',
                  backgroundColor: 'rgba(124, 58, 237, 0.08)',
                },
              }}
            >
              Subir archivo
              <input
                type="file"
                hidden
                name="file"
                onChange={handleDocumentChange}
              />
            </Button>
            {newDocument.file && (
              <Typography variant="body2" sx={{ ml: 2, color: '#9CA3AF' }}>
                Archivo seleccionado: {newDocument.file.name}
              </Typography>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end', p: 2 }}>
        <Button
          onClick={onClose}
          sx={{
            color: '#9CA3AF',
            '&:hover': {
              backgroundColor: 'rgba(156, 163, 175, 0.08)',
            },
          }}
        >
          Cancelar
        </Button>
        <Button
          onClick={handleAddDocument}
          variant="contained"
          sx={{
            backgroundColor: '#7C3AED',
            color: '#FFFFFF',
            '&:hover': {
              backgroundColor: '#6D28D9',
            },
          }}
        >
          Añadir
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const textFieldStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#4A5568',
    },
    '&:hover fieldset': {
      borderColor: '#718096',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#7C3AED',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#A0AEC0',
  },
  '& .MuiInputBase-input': {
    color: '#FFFFFF',
  },
};


function DetalleActuacion() {
  const { id } = useParams();
  const { currentOrganization } = useAuth();
  const [actuacion, setActuacion] = useState({});
  const [editMode, setEditMode] = useState({
    evento: false,
    cliente: false,
    tipo_bolo: false,
    proveedor: false,
    notas_adicionales: false,
    checkList: {
      contrato_enviado: false,
      contrato_firmado: false,
      sin_contrato: false,
      hdr_enviada: false,
      info_pedida: false
    },
  });
  const [editedData, setEditedData] = useState({});

  const [anchorEl, setAnchorEl] = useState(null);

  const estados = ['Confirmado', 'Pendiente', 'Inactivo', 'Cancelado', 'Finalizado'];

  const [openDocumentDialog, setOpenDocumentDialog] = useState(false);
  const [newDocument, setNewDocument] = useState({ nombre: '', tipo: '', file: null });
  const [documents, setDocuments] = useState([]);
  const [paises, setPaises] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [ciudades, setCiudades] = useState([]);
  const [provinciasCargadas, setProvinciasCargadas] = useState(false);

  const spaceTypes = [
    { value: 'indoor_prepared_room', label: 'En Interior - Sala Preparada' },
    { value: 'indoor_unique_event', label: 'En Interior - Evento Único' },
    { value: 'outdoor_roofless_prepared_room', label: 'Exterior Sin Techo - Sala Preparada' },
    { value: 'outdoor_roofless_unique_event', label: 'Exterior Sin Techo - Evento Único' },
    { value: 'other', label: 'Otros' }
  ];


  const handleProvinceChange = async (provinciaNombre) => {
    const selectedPais = paises.find(pais => pais.id === actuacion.pais.id);
    const selectedProvincia = provincias.find(provincia => provincia.name_es === provinciaNombre);
    if (selectedPais && selectedProvincia) {
      // Actualizar el campo `provincia` y limpiar las ciudades dependientes
      setEditedData(prev => ({
        ...prev,
        provincia: provinciaNombre,
        ciudad: '', // Resetear ciudad
      }));

      setCiudades([]); // Limpia las ciudades

      await fetchCiudades(selectedPais.id, selectedProvincia.id); // Cargar nuevas ciudades
    }
  };

  const handleCountryChange = async (paisNombre) => {
    const selectedPais = paises.find(pais => pais.nombre_es === actuacion?.pais?.nombre_es);
    if (selectedPais) {
      setEditedData((prev) => ({
        ...prev,
        pais: selectedPais, // Almacena el objeto completo
        provincia: '', // Limpia dependencias
        ciudad: '',
      }));
      setProvincias([]);
      setCiudades([]);
      setProvinciasCargadas(false); // Resetea el estado de provincias cargadas

      await fetchProvincias(selectedPais.id); // Actualiza provincias
    }
  };


  const fetchCiudades = async (paisId, provinciaId) => {
    try {
      const citiesCollection = collection(db, `paises/${paisId}/provinces/${provinciaId}/localities`);
      const querySnapshot = await getDocs(citiesCollection);
      const ciudadesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      ciudadesList.sort((a, b) => a.name_es.localeCompare(b.name_es));
      setCiudades(ciudadesList);
    } catch (error) {
      console.error('Error fetching ciudades:', error);
    }
  };

  const fetchProvincias = async (paisId) => {
    try {
      if (!actuacion.pais) {
        console.error('El país no está seleccionado.');
        return;
      }
      const provincesCollection = collection(db, `paises/${paisId}/provinces`);
      const querySnapshot = await getDocs(provincesCollection);
      const provinciasList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      provinciasList.sort((a, b) => a.name_es.localeCompare(b.name_es));
      setProvincias(provinciasList);
      setProvinciasCargadas(true); // Indica que las provincias se han cargado
    } catch (error) {
      console.error('Error fetching provincias:', error);
    }
  };

  const fetchPaises = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'paises'));
      const paisesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      paisesList.sort((a, b) => a.nombre_es.localeCompare(b.nombre_es));
      setPaises(paisesList);
    } catch (error) {
      console.error('Error fetching paises:', error);
    }
  };



  const tiposDocumento = [
    'Facturas',
    'Contrato',
    'Rider Técnico',
    'Rider Hospitality',
    'Hoja de Ruta',
    'Otros'
  ];

  const [colaboradores, setColaboradores] = useState([]);
  const [openColaboradoresDialog, setOpenColaboradoresDialog] = useState(false);
  const [selectedColaboradores, setSelectedColaboradores] = useState([]);
  const [colaboradoresActivos, setColaboradoresActivos] = useState([]);

  const [contactos, setContactos] = useState([]);
  const [cliente, setCliente] = useState(null);
  const [proveedor, setProveedor] = useState(null);

  const [imagenEvento, setImagenEvento] = useState(null);
  const [imagenPreview, setImagenPreview] = useState(null);
  const [nombreImagen, setNombreImagen] = useState('');

  const db = getFirestore();

  useEffect(() => {
    fetchPaises();
  }, []);

  useEffect(() => {
    if (actuacion.pais?.id) {
      fetchProvincias(actuacion.pais.id);
    }
  }, [actuacion.pais]);

  useEffect(() => {
    if (actuacion.provincia && provinciasCargadas) {
      const selectedPais = paises.find(pais => pais.id === actuacion.pais.id);
      const selectedProvincia = provincias.find(provincia => provincia.name_es === actuacion.provincia);
      if (selectedPais && selectedProvincia) {
        fetchCiudades(selectedPais.id, selectedProvincia.id);
      }
    }
  }, [actuacion.provincia, provinciasCargadas]);


  useEffect(() => {
    const fetchActuacion = async () => {
      if (!currentOrganization || !id) {
        console.log("Organización o ID no disponibles");
        return;
      }

      const actuacionRef = doc(db, 'organizaciones', currentOrganization.organizacion_id, 'eventos', id);

      try {
        const actuacionSnap = await getDoc(actuacionRef);

        if (actuacionSnap.exists()) {
          const actuacionData = {
            id: actuacionSnap.id,
            ...actuacionSnap.data(),
            notas_adicionales: actuacionSnap.data().notas_adicionales || '',
            checkList: actuacionSnap.data().checkList || {
              contrato_enviado: false,
              contrato_firmado: false,
              sin_contrato: false,
              hdr_enviada: false,
              info_pedida: false
            },
            documentos: actuacionSnap.data().documentos || [],
            cliente: actuacionSnap.data().cliente || null,
            imagen_evento: actuacionSnap.data().imagen_evento || null,
            proveedor: actuacionSnap.data().proveedor || null,

          };
          setActuacion(actuacionData);
          setEditedData(actuacionData);
          setDocuments(actuacionData.documentos);
          setCliente(actuacionData.cliente);

          // Establece el proveedor asociado si existe
          if (actuacionData.proveedor) {
            setProveedor(actuacionData.proveedor);
          }

          // Cargar colaboradores activos
          await fetchColaboradoresActivos(actuacionSnap.id);
          if (actuacionData.imagen_evento) {
            setImagenPreview(actuacionData.imagen_evento.imagen_url);
            setNombreImagen(actuacionData.imagen_evento.nombre);
          }
          console.log('Actuación encontrada:', actuacionData);
          await fetchPaises();
        } else {
          console.log('No se encontró la actuación');
        }
      } catch (error) {
        console.error("Error al obtener la actuación:", error);
      }
    };

    const fetchColaboradoresActivos = async (eventoId) => {
      if (!currentOrganization || !eventoId) return;

      const colaboradoresEventoRef = collection(db, 'organizaciones', currentOrganization.organizacion_id, 'eventos', eventoId, 'colaboradores');

      try {
        const snapshot = await getDocs(colaboradoresEventoRef);
        const colaboradoresData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setColaboradoresActivos(colaboradoresData);
        setSelectedColaboradores(colaboradoresData.map(c => c.id));
      } catch (error) {
        console.error("Error al obtener colaboradores activos:", error);
      }
    };

    fetchActuacion();
  }, [currentOrganization, id]);

  useEffect(() => {
    const fetchColaboradores = async () => {
      if (!currentOrganization) return;


      const colaboradoresRef = collection(db, 'organizaciones', currentOrganization.organizacion_id, 'colaboradores');

      try {
        const snapshot = await getDocs(colaboradoresRef);
        const colaboradoresData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setColaboradores(colaboradoresData);
      } catch (error) {
        console.error("Error al obtener colaboradores:", error);
      }
    };

    fetchColaboradores();
  }, [currentOrganization]);

  const cargarContactos = useCallback(async () => {
    if (!currentOrganization) return;

    try {

      const contactosRef = collection(db, 'organizaciones', currentOrganization.organizacion_id, 'contactos');
      const snapshot = await getDocs(contactosRef);

      const contactosPromises = snapshot.docs.map(async (docSnapshot) => {
        const contactoData = docSnapshot.data();

        if (contactoData.tipo === 'Empresa' && contactoData.organizacion_id) {
          try {
            const empresaDocRef = doc(db, 'organizaciones', contactoData.organizacion_id);
            const empresaDoc = await getDoc(empresaDocRef);

            if (empresaDoc.exists()) {
              const empresaData = empresaDoc.data();
              return {
                id: docSnapshot.id,
                ...contactoData,
                nombre: empresaData.nombre || 'Nombre no disponible',
                email: empresaData.email || 'Email no disponible',
                telefono: empresaData.telefono || 'Teléfono no disponible',
                organizacion_id: contactoData.organizacion_id,
                label: `${empresaData.nombre} (Empresa)`
              };
            } else {
              return {
                id: docSnapshot.id,
                ...contactoData,
                nombre: 'Empresa no encontrada',
                email: 'N/A',
                telefono: 'N/A',
                label: 'Empresa no encontrada'
              };
            }
          } catch (empresaError) {
            console.error("Error al cargar datos de la empresa:", empresaError);
            return {
              id: docSnapshot.id,
              ...contactoData,
              nombre: 'Error al cargar empresa',
              email: 'N/A',
              telefono: 'N/A',
              label: 'Error al cargar empresa'
            };
          }
        } else {
          return {
            id: docSnapshot.id,
            ...contactoData,
            nombre: contactoData.nombre || 'Nombre no disponible',
            email: contactoData.email || 'Email no disponible',
            telefono: contactoData.telefono || 'Teléfono no disponible',
            label: `${contactoData.nombre} (${contactoData.tipo})`
          };
        }
      });

      const contactosList = await Promise.all(contactosPromises);
      setContactos(contactosList);
    } catch (error) {
      console.error('Error al cargar contactos:', error);
    }
  }, [currentOrganization]);

  useEffect(() => {
    if (currentOrganization) {
      cargarContactos();
    }
  }, [currentOrganization, cargarContactos]);



  const handleStatusClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleStatusClose = () => {
    setAnchorEl(null);
  };

  const handleStatusChange = async (newStatus) => {
    if (!currentOrganization) {
      console.error("No hay organización seleccionada");
      return;
    }

    const actuacionRef = doc(db, 'organizaciones', currentOrganization.organizacion_id, 'eventos', id);

    try {
      await updateDoc(actuacionRef, { estado: newStatus.toLowerCase() });
      setActuacion(prev => ({ ...prev, estado: newStatus.toLowerCase() }));
    } catch (error) {
      console.error("Error al actualizar el estado:", error);
    }
    handleStatusClose();
  };

  const handleEdit = (section) => {
    setEditMode({ ...editMode, [section]: true });
  };

  const handleCityChange = (ciudadNombre) => {
    setActuacion(prev => ({
      ...prev,
      ciudad: ciudadNombre
    }));
    setEditedData(prev => ({
      ...prev,
      ciudad: ciudadNombre
    }));
  };

  const handleChange = (section, field, value) => {
    if (section === 'notas_adicionales') {
      // Caso específico para notas_adicionales
      setEditedData(prev => ({
        ...prev,
        notas_adicionales: value,
      }));
    } else if (section === 'checkList') {
      // Caso específico para checkList
      setEditedData(prev => ({
        ...prev,
        checkList: {
          ...prev.checkList,
          [field]: value,
        },
      }));
    } else if (section) {
      // Caso general para secciones (e.g., tipo_bolo, proveedor, etc.)
      setEditedData(prev => ({
        ...prev,
        [section]: {
          ...prev[section],
          [field]: validateValue(field, value), // Validación para evitar errores
        },
      }));
    } else {
      // Caso para campos en el nivel raíz (e.g., espectadores, nombre, etc.)
      setEditedData(prev => ({
        ...prev,
        [field]: validateValue(field, value), // Validación para evitar errores
      }));
    }
  };

  // Función para validar y normalizar valores
  const validateValue = (field, value) => {
    if (field === 'inicio' && value) {
      return dayjs(value).isValid() ? dayjs(value).toISOString() : null;

    }
    return value; // Retorna el valor sin cambios para otros campos
  };

  const convertirFechaISO = (fecha) => {
    const [dia, mes, anio] = fecha.split('-'); // Divide la fecha en día, mes y año
    return `${anio}-${mes}-${dia}`; // Devuelve en formato ISO
  };

  const handleProveedorChange = async (event, newValue) => {
    if (!newValue) {
      setProveedor(null);
      setEditedData((prev) => ({ ...prev, proveedor: null })); // Aseguramos la limpieza

      return;
    }

    const proveedorData = {
      nombre: newValue.nombre,
      email: newValue.email,
      telefono: newValue.telefono,
      tipo: newValue.tipo,
      ...(newValue.tipo === 'Empresa' && { organizacion_id: newValue.organizacion_id }),
    };

    setProveedor(proveedorData);
    setEditedData((prev) => ({ ...prev, proveedor: proveedorData })); // Actualizamos `editedData`


    try {
      const actuacionRef = doc(db, 'organizaciones', currentOrganization.organizacion_id, 'eventos', id);
      await updateDoc(actuacionRef, { proveedor: proveedorData });
      setActuacion((prev) => ({ ...prev, proveedor: proveedorData }));
    } catch (error) {
      console.error('Error al actualizar el proveedor:', error);
    }
  };


  const handleSave = async (section) => {
    if (!currentOrganization) {
      console.error("No hay organización seleccionada");
      return;
    }

    const actuacionRef = doc(db, 'organizaciones', currentOrganization.organizacion_id, 'eventos', id);

    try {
      let dataToSave;
      if (section === 'notas_adicionales' || section === 'checkList') {
        dataToSave = { [section]: editedData[section] };
      } else if (section === null) {
        dataToSave = { ...editedData };
      } else {
        dataToSave = { [section]: editedData[section] };
      }

      // Filtra los valores undefined
      const filteredDataToSave = JSON.parse(
        JSON.stringify(dataToSave, (key, value) => {
          // Conservar Timestamps como están
          if (value instanceof Timestamp) {
            return value; // Deja el objeto sin cambios
          }
          return value === undefined ? null : value; // Sustituye undefined por null
        })
      );

      // Convertir las fechas al formato Timestamp de Firebase
      if (editedData.inicio) {
        const fechaInicio = typeof editedData.inicio === 'string' ? new Date(editedData.inicio) : editedData.inicio.toDate();
        filteredDataToSave.inicio = Timestamp.fromDate(fechaInicio); // Convertir a Timestamp
      } else if (actuacion.inicio) {
        filteredDataToSave.inicio = actuacion.inicio; // Mantener el valor original si no se modifica
      }

      if (editedData.final) {
        const fechaFinal = new Date(editedData.final.seconds * 1000 + editedData.final.nanoseconds / 1000000);
        filteredDataToSave.final = Timestamp.fromDate(fechaFinal); // Convertir a Timestamp
      }



      await updateDoc(actuacionRef, filteredDataToSave);

      if (section === 'notas_adicionales' || section === 'checkList') {
        setActuacion(prev => ({ ...prev, [section]: editedData[section] }));
      } else if (section === null) {
        setActuacion(editedData);
      } else {
        setActuacion(prev => ({ ...prev, [section]: editedData[section] }));
      }

      setEditMode({ ...editMode, [section]: false });
    } catch (error) {
      console.error("Error al actualizar:", error);
    }
  };

  const handleCancel = (section) => {
    setEditedData(prev => ({ ...prev, [section]: actuacion[section] }));
    setEditMode({ ...editMode, [section]: false });
  };

  const handleCheckListChange = async (key, checked) => {
    if (!currentOrganization) {
      console.error("No hay organización seleccionada");
      return;
    }

    const actuacionRef = doc(db, 'organizaciones', currentOrganization.organizacion_id, 'eventos', id);

    const updatedCheckList = { ...actuacion.checkList, [key]: checked };

    try {
      await updateDoc(actuacionRef, { checkList: updatedCheckList });
      setActuacion(prev => ({ ...prev, checkList: updatedCheckList }));
    } catch (error) {
      console.error("Error al actualizar el Check List:", error);
    }
  };

  const handleOpenDocumentDialog = () => {
    setOpenDocumentDialog(true);
  };

  const handleCloseDocumentDialog = () => {
    setOpenDocumentDialog(false);
    setNewDocument({ nombre: '', tipo: '', file: null });
  };

  const handleDocumentChange = (event) => {
    const { name, value, files } = event.target;
    setNewDocument(prev => ({
      ...prev,
      [name]: files ? files[0] : value
    }));
  };

  const handleAddDocument = async () => {
    if (!newDocument.nombre || !newDocument.tipo || !newDocument.file) {
      alert('Por favor, complete todos los campos y seleccione un archivo.');
      return;
    }

    try {
      const filePath = `documents/${currentOrganization.organizacion_id}/${id}/${newDocument.file.name}`;
      console.log('Intentando subir documento a:', filePath);
      const downloadURL = await uploadDocument(newDocument.file, filePath);
      console.log('Documento subido exitosamente. URL:', downloadURL);

      const newDocumentData = {
        id: Date.now().toString(), // ID único
        nombre: newDocument.nombre,
        tipo: newDocument.tipo,
        url: downloadURL,
        eliminado: false
      };

      const updatedDocuments = [...documents, newDocumentData];
      setDocuments(updatedDocuments);

      console.log('Actualizando documentos de la actuación...');
      await updateActuacionDocuments(currentOrganization.organizacion_id, id, updatedDocuments);
      console.log('Documentos actualizados exitosamente');

      handleCloseDocumentDialog();
    } catch (error) {
      console.error("Error detallado al subir el documento:", error);
      alert(`Error al subir el documento: ${error.message}`);
    }
  };


  const formatDate = (date) => {
    if (!date) return 'Fecha no disponible';

    // Si date es un objeto Timestamp de Firestore
    if (date && typeof date.toDate === 'function') {
      date = date.toDate();
    }

    // Si date es un string, intentamos convertirlo a Date
    if (typeof date === 'string') {
      date = new Date(date);
    }

    // Verificamos si la fecha es válida
    if (!(date instanceof Date) || isNaN(date)) {
      return 'Fecha inválida';
    }


    return new Intl.DateTimeFormat('es-ES', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'Europe/Madrid', // Asegura que se use la zona horaria correcta
      hour12: false, // Configura el formato de 24 horas
    }).format(date);
  };

  const handleDeleteDocument = async (documentId) => {
    if (!currentOrganization || !id) {
      console.error("Organización o ID de evento no disponibles");
      alert("Error: Información de organización o evento no disponible");
      return;
    }

    try {
      await markDocumentAsDeleted(currentOrganization.organizacion_id, id, documentId);
      // Actualizar el estado local
      setDocuments(prevDocs => prevDocs.map(doc =>
        doc.id === documentId ? { ...doc, eliminado: true } : doc
      ));
    } catch (error) {
      console.error("Error al eliminar el documento:", error);
      alert(`Error al eliminar el documento: ${error.message}`);
    }
  };

  const handleOpenColaboradoresDialog = () => {
    setOpenColaboradoresDialog(true);
  };

  const handleCloseColaboradoresDialog = () => {
    setOpenColaboradoresDialog(false);
  };

  const handleColaboradorToggle = (colaboradorId) => {
    setSelectedColaboradores(prev =>
      prev.includes(colaboradorId)
        ? prev.filter(id => id !== colaboradorId)
        : [...prev, colaboradorId]
    );
  };

  const handleSelectAllColaboradores = () => {
    if (selectedColaboradores.length === colaboradores.length) {
      setSelectedColaboradores([]);
    } else {
      setSelectedColaboradores(colaboradores.map(c => c.id));
    }
  };

  const handleSaveColaboradores = async () => {
    if (!currentOrganization || !id) return;

    const colaboradoresEventoRef = collection(db, 'organizaciones', currentOrganization.organizacion_id, 'eventos', id, 'colaboradores');

    try {
      // Eliminar colaboradores existentes
      const snapshot = await getDocs(colaboradoresEventoRef);
      const deletePromises = snapshot.docs.map(doc => deleteDoc(doc.ref));
      await Promise.all(deletePromises);

      // Añadir nuevos colaboradores seleccionados
      const addPromises = selectedColaboradores.map(colaboradorId => {
        const colaborador = colaboradores.find(c => c.id === colaboradorId);
        return setDoc(doc(colaboradoresEventoRef, colaboradorId), {
          nombre: colaborador.nombre,
          rol: colaborador.rol,
          telefono: colaborador.telefono
        });
      });
      await Promise.all(addPromises);

      // Actualizar la lista de colaboradores activos
      const updatedColaboradores = colaboradores.filter(c => selectedColaboradores.includes(c.id));
      setColaboradoresActivos(updatedColaboradores);

      handleCloseColaboradoresDialog();
    } catch (error) {
      console.error("Error al guardar colaboradores:", error);
    }
  };

  const handleClienteChange = async (event, newValue) => {
    if (!newValue) {
      setCliente(null);
      return;
    }

    const clienteData = {
      nombre: newValue.nombre,
      email: newValue.email,
      tipo: newValue.tipo,
      ...(newValue.tipo === 'Empresa' && { organizacion_id: newValue.organizacion_id })
    };

    setCliente(clienteData);

    try {

      const actuacionRef = doc(db, 'organizaciones', currentOrganization.organizacion_id, 'eventos', id);
      await updateDoc(actuacionRef, { cliente: clienteData });
      setActuacion(prev => ({ ...prev, cliente: clienteData }));
    } catch (error) {
      console.error("Error al actualizar el cliente:", error);
    }
  };

  const handleImagenChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImagenEvento(file);
      setImagenPreview(URL.createObjectURL(file));
    }
  };

  const handleNombreImagenChange = (event) => {
    setNombreImagen(event.target.value);
  };

  const handleSubirImagen = async () => {
    if (!imagenEvento || !nombreImagen) {
      alert('Por favor, selecciona una imagen y dale un nombre.');
      return;
    }

    const storage = getStorage();
    const imagenRef = ref(storage, `images/eventos/${id}_${imagenEvento.name}`);

    try {
      const snapshot = await uploadBytes(imagenRef, imagenEvento);
      const downloadURL = await getDownloadURL(snapshot.ref);

      const imagenEventoData = {
        nombre: nombreImagen,
        imagen_url: downloadURL
      };

      const actuacionRef = doc(getFirestore(), 'organizaciones', currentOrganization.organizacion_id, 'eventos', id);
      await updateDoc(actuacionRef, { imagen_evento: imagenEventoData });

      setActuacion(prev => ({ ...prev, imagen_evento: imagenEventoData }));
      alert('Imagen subida con éxito');
    } catch (error) {
      console.error("Error al subir la imagen:", error);
      alert('Error al subir la imagen');
    }
  };

  const renderImagenEventoCard = () => (
    <Card sx={{ height: '100%', backgroundColor: '#1E1E1E', color: '#FFFFFF', borderRadius: 2, boxShadow: 3, position: 'relative' }}>
      <CardContent>
        <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <ImageIcon sx={{ mr: 1, color: '#7C3AED' }} />
          Imagen del Evento
        </Typography>
        {imagenPreview && (
          <Box sx={{ mb: 2 }}>
            <img src={imagenPreview} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'contain' }} />
          </Box>
        )}
        <TextField
          fullWidth
          label="Nombre de la imagen"
          value={nombreImagen}
          onChange={handleNombreImagenChange}
          sx={{
            mb: 2,
            '& .MuiInputBase-input': { color: '#FFFFFF' },
            '& .MuiInputLabel-root': { color: '#9CA3AF' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.23)' },
              '&:hover fieldset': { borderColor: '#7C3AED' },
              '&.Mui-focused fieldset': { borderColor: '#7C3AED' },
            },
          }}
        />
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="imagen-evento-upload"
          type="file"
          onChange={handleImagenChange}
        />
        <label htmlFor="imagen-evento-upload">
          <Button
            variant="contained"
            component="span"
            startIcon={<UploadIcon />}
            sx={{
              backgroundColor: '#7C3AED',
              '&:hover': { backgroundColor: '#6D28D9' },
              mb: 2,
              color: '#FFFFFF',
            }}
          >
            Seleccionar Imagen
          </Button>
        </label>
        <Button
          fullWidth
          variant="contained"
          onClick={handleSubirImagen}
          sx={{
            backgroundColor: '#7C3AED',
            '&:hover': { backgroundColor: '#6D28D9' },
            color: '#FFFFFF',
          }}
        >
          Subir Imagen
        </Button>
      </CardContent>
    </Card>
  );

  if (!actuacion) {
    return <Typography>Cargando...</Typography>;
  }

  const getStatusStyle = (estado) => {
    if (!estado) return { color: '#1F2937', backgroundColor: '#F3F4F6' };

    switch (estado.toLowerCase()) {
      case 'cancelado': return { color: '#FFFFFF', backgroundColor: '#DC2626' };
      case 'inactivo': return { color: '#4B5563', backgroundColor: '#E5E7EB' };
      case 'pendiente': return { color: '#92400E', backgroundColor: '#FEF3C7' };
      case 'confirmado': return { color: '#065F46', backgroundColor: '#D1FAE5' };
      case 'finalizado': return { color: '#FFFFFF', backgroundColor: '#3B82F6' };
      default: return { color: '#1F2937', backgroundColor: '#F3F4F6' };
    }
  };

  const checkListOrder = [
    'contrato_enviado',
    'contrato_firmado',
    'sin_contrato',
    'hdr_enviada',
    'info_pedida'
  ];

  const renderCheckList = () => {
    if (!actuacion || !actuacion.checkList) {
      return null; // O un componente de carga, si prefieres
    }

    return (
      <Card sx={{ height: '100%', backgroundColor: '#1E1E1E', color: '#FFFFFF', borderRadius: 2, boxShadow: 3, position: 'relative' }}>
        <CardContent>
          <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <DescriptionIcon sx={{ mr: 1, color: '#7C3AED' }} />
            Check List
          </Typography>
          <Grid container spacing={2}>
            {checkListOrder.map((key) => (
              <Grid item xs={6} key={key}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actuacion.checkList[key] || false}
                      onChange={(e) => handleCheckListChange(key, e.target.checked)}
                      sx={{
                        color: '#7C3AED',
                        '&.Mui-checked': {
                          color: '#7C3AED',
                        },
                      }}
                    />
                  }
                  label={
                    <Typography sx={{ color: '#FFFFFF', fontSize: '0.9rem' }}>
                      {key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                    </Typography>
                  }
                  sx={{ display: 'flex', m: 0 }}
                />
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    );
  };


  const renderProveedorCard = () => (
    <Card
      sx={{
        height: '100%',
        backgroundColor: '#1E1E1E',
        color: '#FFFFFF',
        borderRadius: 2,
        boxShadow: 3,
        position: 'relative',
      }}
    >
      <CardContent>
        <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <BusinessIcon sx={{ mr: 1, color: '#7C3AED' }} />
          Nombre del Proveedor
        </Typography>
        {editMode.proveedor ? (
          <>
            {/* Autocomplete para seleccionar proveedor */}
            <Autocomplete
              options={contactos}
              getOptionLabel={(option) => option.label || ''}
              value={contactos.find(
                (contacto) => contacto.nombre === proveedor?.nombre && contacto.email === proveedor?.email
              ) || null}
              onChange={handleProveedorChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Seleccionar proveedor"
                  variant="outlined"
                  fullWidth
                  sx={{
                    mb: 2,
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.23)' },
                      '&:hover fieldset': { borderColor: '#7C3AED' },
                      '&.Mui-focused fieldset': { borderColor: '#7C3AED' },
                    },
                    '& .MuiInputLabel-root': { color: '#9CA3AF' },
                    '& .MuiInputBase-input': { color: '#FFFFFF' },
                  }}
                />
              )}
              sx={{
                '& .MuiAutocomplete-popupIndicator': { color: '#7C3AED' },
                '& .MuiAutocomplete-clearIndicator': { color: '#7C3AED' },
              }}
            />
            {/* Botones de Guardar y Cancelar */}
            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
              <Button
                variant="contained"
                onClick={() => handleSave('proveedor')}
                sx={{ backgroundColor: '#7C3AED', '&:hover': { backgroundColor: '#6D28D9' } }}
              >
                Guardar
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleCancel('proveedor')}
                sx={{ color: '#7C3AED', borderColor: '#7C3AED', '&:hover': { borderColor: '#6D28D9' } }}
              >
                Cancelar
              </Button>
            </Stack>
          </>
        ) : (
          <>
            {/* Muestra los detalles del proveedor si no está en modo edición */}
            {proveedor ? (
              <Box sx={{ mt: 2 }}>
                <Typography variant="body1" gutterBottom>
                  <strong>Nombre:</strong> {proveedor.nombre || 'N/A'}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Email:</strong> {proveedor.email || 'N/A'}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Teléfono:</strong> {proveedor.telefono || 'N/A'}
                </Typography>
              </Box>
            ) : (
              <Typography sx={{ color: '#9CA3AF' }}>No hay proveedor seleccionado</Typography>
            )}
            {/* Botón de Editar */}
            <IconButton
              sx={{ position: 'absolute', top: 8, right: 8, color: '#7C3AED' }}
              onClick={() => handleEdit('proveedor')}
            >
              <EditIcon />
            </IconButton>
          </>
        )}
      </CardContent>
    </Card>
  );

  const renderCard = (title, icon, section, fields) => (
    <Card sx={{ height: '100%', backgroundColor: '#1E1E1E', color: '#FFFFFF', borderRadius: 2, boxShadow: 3, position: 'relative' }}>
      <IconButton
        sx={{ position: 'absolute', top: 8, right: 8, color: '#7C3AED' }}
        onClick={() => handleEdit(section)}
      >
        <EditIcon />
      </IconButton>
      <CardContent>
        <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          {icon}
          {title}
        </Typography>
        {section === 'cliente' && (
          <Autocomplete
            options={contactos}
            getOptionLabel={(option) => option.label || ''}
            value={cliente ? contactos.find(c => c.nombre === cliente.nombre) : null}
            onChange={handleClienteChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Seleccionar cliente"
                variant="outlined"
                fullWidth
                sx={{
                  mb: 2,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.23)' },
                    '&:hover fieldset': { borderColor: '#7C3AED' },
                    '&.Mui-focused fieldset': { borderColor: '#7C3AED' },
                  },
                  '& .MuiInputLabel-root': { color: '#9CA3AF' },
                  '& .MuiInputBase-input': { color: '#FFFFFF' },
                }}
              />
            )}
            sx={{
              '& .MuiAutocomplete-popupIndicator': { color: '#7C3AED' },
              '& .MuiAutocomplete-clearIndicator': { color: '#7C3AED' },
            }}
          />
        )}
        {editMode[section] ? (
          fields.map(({ field, label }) => {

            const fieldValue = section ? editedData[section]?.[field] : editedData[field];

            return (
              <TextField
                key={field}
                fullWidth
                label={label}
                value={fieldValue || ''}
                onChange={(e) => handleChange(section, field, e.target.value)}
                margin="normal"
                sx={{
                  '& .MuiInputBase-input': { color: '#FFFFFF' },
                  '& .MuiInputLabel-root': { color: '#FFFFFF' },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: '#FFFFFF' },
                    '&:hover fieldset': { borderColor: '#7C3AED' },
                    '&.Mui-focused fieldset': { borderColor: '#7C3AED' },
                  },
                }}
              />
            );
          })
        ) : (
          fields.map(({ field, label }) => {
            const value = section ? actuacion[section]?.[field] : actuacion[field];
            return (
              <Box key={field} sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography sx={{ fontWeight: 'bold' }}>{label}:</Typography>
                <Typography>{field === "inicio" ? formatDate(value) : value || 'N/A'}</Typography>
              </Box>
            );
          })

        )}
        {editMode[section] && (
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Button variant="contained" onClick={() => handleSave(section)} sx={{ backgroundColor: '#7C3AED', '&:hover': { backgroundColor: '#6D28D9' } }}>
              Guardar
            </Button>
            <Button variant="outlined" onClick={() => handleCancel(section)} sx={{ color: '#7C3AED', borderColor: '#7C3AED', '&:hover': { borderColor: '#6D28D9' } }}>
              Cancelar
            </Button>
          </Stack>
        )}
      </CardContent>
    </Card>
  );

  const renderCardProveedor = (title, icon, section, fields) => (
    <Card sx={{ height: '100%', backgroundColor: '#1E1E1E', color: '#FFFFFF', borderRadius: 2, boxShadow: 3, position: 'relative' }}>
      <IconButton
        sx={{ position: 'absolute', top: 8, right: 8, color: '#7C3AED' }}
        onClick={() => handleEdit(section)}
      >
        <EditIcon />
      </IconButton>
      <CardContent>
        <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          {icon}
          {title}
        </Typography>
        {section === 'proveedor' && (
          <Autocomplete
            options={contactos}
            getOptionLabel={(option) => option.label || ''}
            value={cliente ? contactos.find(c => c.nombre === proveedor.nombre) : null}
            onChange={handleProveedorChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Seleccionar cliente"
                variant="outlined"
                fullWidth
                sx={{
                  mb: 2,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.23)' },
                    '&:hover fieldset': { borderColor: '#7C3AED' },
                    '&.Mui-focused fieldset': { borderColor: '#7C3AED' },
                  },
                  '& .MuiInputLabel-root': { color: '#9CA3AF' },
                  '& .MuiInputBase-input': { color: '#FFFFFF' },
                }}
              />
            )}
            sx={{
              '& .MuiAutocomplete-popupIndicator': { color: '#7C3AED' },
              '& .MuiAutocomplete-clearIndicator': { color: '#7C3AED' },
            }}
          />
        )}
        {editMode[section] ? (
          fields.map(({ field, label }) => {
            const fieldValue = section ? editedData[section]?.[field] : editedData[field];

            return (
              <TextField
                key={field}
                fullWidth
                label={label}
                value={fieldValue || ''}
                onChange={(e) => handleChange(section, field, e.target.value)}
                margin="normal"
                sx={{
                  '& .MuiInputBase-input': { color: '#FFFFFF' },
                  '& .MuiInputLabel-root': { color: '#FFFFFF' },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: '#FFFFFF' },
                    '&:hover fieldset': { borderColor: '#7C3AED' },
                    '&.Mui-focused fieldset': { borderColor: '#7C3AED' },
                  },
                }}
              />
            );
          })
        ) : (
          fields.map(({ field, label }) => {
            const value = section ? actuacion[section]?.[field] : actuacion[field];
            return (
              <Box key={field} sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography sx={{ fontWeight: 'bold' }}>{label}:</Typography>
                <Typography>{field === "inicio" ? formatDate(value) : value || 'N/A'}</Typography>
              </Box>
            );
          })

        )}
        {editMode[section] && (
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Button variant="contained" onClick={() => handleSave(section)} sx={{ backgroundColor: '#7C3AED', '&:hover': { backgroundColor: '#6D28D9' } }}>
              Guardar
            </Button>
            <Button variant="outlined" onClick={() => handleCancel(section)} sx={{ color: '#7C3AED', borderColor: '#7C3AED', '&:hover': { borderColor: '#6D28D9' } }}>
              Cancelar
            </Button>
          </Stack>
        )}
      </CardContent>
    </Card>
  );

  const renderDetailsCard = (title, icon, section, fields) => (
    <Card sx={{ height: '100%', backgroundColor: '#1E1E1E', color: '#FFFFFF', borderRadius: 2, boxShadow: 3, position: 'relative' }}>
      <IconButton
        sx={{ position: 'absolute', top: 8, right: 8, color: '#7C3AED' }}
        onClick={() => handleEdit(section)}
      >
        <EditIcon />
      </IconButton>
      <CardContent>
        <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          {icon}
          {title}
        </Typography>
        {editMode[section] ? (
          fields.map(({ field, label }) => {
            const isCountryField = field === 'pais';
            const isProvinceField = field === 'provincia';
            const isCityField = field === 'ciudad';
            const isSpaceType = field === 'space_type';
            const fieldValue = section ? editedData[section]?.[field] : editedData[field];

            if (isSpaceType) {
              return (<FormControl fullWidth sx={{ ...textFieldStyle, mt: 2 }} required>
                <InputLabel id="space-type-label">Tipo de Espacio</InputLabel>
                <Select
                  labelId="space-type-label"
                  value={fieldValue || ''}
                  label="Tipo de Espacio"
                  onChange={(e) => handleChange(null, 'space_type', e.target.value)}
                >
                  {spaceTypes.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>);
            }

            if (isCountryField) {
              return (
                <FormControl fullWidth sx={{ ...textFieldStyle, mb: 2 }} required>
                  <InputLabel id="pais-label">País</InputLabel>
                  <Select
                    labelId="pais-label"
                    value={actuacion.pais?.nombre_es || ''}
                    label="País"
                    onChange={(e) => handleCountryChange(e.target.value)}
                  >
                    {paises.map((pais) => (
                      <MenuItem key={pais.id} value={pais.nombre_es}>
                        {pais.nombre_es}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }

            if (isProvinceField) {
              return (
                <FormControl fullWidth sx={{ ...textFieldStyle, mb: 2 }} required key={field}>
                  <InputLabel id="provincia-label">Provincia</InputLabel>
                  <Select
                    labelId="provincia-label"
                    value={fieldValue || actuacion.provincia || ''}
                    label="Provincia"
                    onChange={(e) => handleProvinceChange(e.target.value)}
                  >
                    {provincias.map((provincia) => (
                      <MenuItem key={provincia.id} value={provincia.name_es}>
                        {provincia.name_es}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }

            if (isCityField) {
              return (
                <FormControl fullWidth sx={{ ...textFieldStyle }} required key={field}>
                  <InputLabel id="ciudad-label">Ciudad</InputLabel>
                  <Select
                    labelId="ciudad-label"
                    value={fieldValue || ''}
                    label="Ciudad"
                    onChange={(e) => handleCityChange(e.target.value)}
                  >
                    {ciudades.map((ciudad) => (
                      <MenuItem key={ciudad.id} value={ciudad.name_es}>
                        {ciudad.name_es}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }

            const isDatetimeField = field === "inicio";

            if (isDatetimeField) {
              const formattedDateValue = fieldValue && typeof fieldValue === 'object' && fieldValue.seconds
                ? new Date(fieldValue.seconds * 1000).toISOString().slice(0, 16)
                : fieldValue;

              return (
                <ThemeProvider theme={darkTheme}>
                  <LocalizationProvider key={field} dateAdapter={AdapterDayjs} adapterLocale="es">
                    <DateTimePicker
                      fullWidth
                      label={label}
                      inputFormat="DD/MM/YYYY"
                      sx={{ ...textFieldStyle, mb: 2 }}
                      value={formattedDateValue ? dayjs(formattedDateValue) : null} // Convertimos a dayjs
                      onChange={(newValue) => handleChange(section, field, newValue ? newValue.toISOString() : null)}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          fullWidth
                          margin="normal"
                          sx={{
                            '& .MuiInputBase-input': { color: '#FFFFFF' },
                            '& .MuiInputLabel-root': { color: '#FFFFFF' },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': { borderColor: '#FFFFFF' },
                              '&:hover fieldset': { borderColor: '#7C3AED' },
                              '&.Mui-focused fieldset': { borderColor: '#7C3AED' },
                            },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </ThemeProvider>
              );
            }



            return (
              <TextField
                key={field}
                fullWidth
                label={label}
                value={fieldValue || ''}
                onChange={(e) => handleChange(section, field, e.target.value)}
                margin="normal"
                sx={{
                  '& .MuiInputBase-input': { color: '#FFFFFF' },
                  '& .MuiInputLabel-root': { color: '#FFFFFF' },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: '#FFFFFF' },
                    '&:hover fieldset': { borderColor: '#7C3AED' },
                    '&.Mui-focused fieldset': { borderColor: '#7C3AED' },
                  },
                }}
              />
            );
          })
        ) : (
          fields.map(({ field, label }) => {
            const value = section ? actuacion[section]?.[field] : actuacion[field];
            let displayValue;
            if (field === 'pais' && typeof value === 'object') {
              displayValue = value.nombre_es;
            } else if (field === 'inicio' && value && typeof value === 'object' && value.seconds) {
              displayValue = new Date(value.seconds * 1000).toLocaleString();
            } else if (field === 'space_type') {
              displayValue = spaceTypes.find(type => type.value === value)?.label;
            }
            else {
              displayValue = value;
            }

            return (
              <Box key={field} sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography sx={{ fontWeight: 'bold' }}>{label}:</Typography>
                <Typography>{displayValue || 'N/A'}</Typography>
              </Box>
            );
          })
        )}
        {editMode[section] && (
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Button variant="contained" onClick={() => handleSave(section)} sx={{ backgroundColor: '#7C3AED', '&:hover': { backgroundColor: '#6D28D9' } }}>
              Guardar
            </Button>
            <Button variant="outlined" onClick={() => handleCancel(section)} sx={{ color: '#7C3AED', borderColor: '#7C3AED', '&:hover': { borderColor: '#6D28D9' } }}>
              Cancelar
            </Button>
          </Stack>
        )}
      </CardContent>
    </Card>
  );

  const renderDescripcionCard = () => (
    <Card sx={{ height: '100%', backgroundColor: '#1E1E1E', color: '#FFFFFF', borderRadius: 2, boxShadow: 3, position: 'relative' }}>
      <IconButton
        sx={{ position: 'absolute', top: 8, right: 8, color: '#7C3AED' }}
        onClick={() => handleEdit('notas_adicionales')}
      >
        <EditIcon />
      </IconButton>
      <CardContent>
        <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <DescriptionIcon sx={{ mr: 1, color: '#7C3AED' }} />
          Descripciónss
        </Typography>
        {editMode.notas_adicionales ? (
          <TextField
            fullWidth
            multiline
            rows={4}
            value={editedData.notas_adicionales || ''}
            onChange={(e) => handleChange('notas_adicionales', null, e.target.value)}
            sx={{
              '& .MuiInputBase-input': { color: '#FFFFFF' },
              '& .MuiInputLabel-root': { color: '#FFFFFF' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: '#FFFFFF' },
                '&:hover fieldset': { borderColor: '#7C3AED' },
                '&.Mui-focused fieldset': { borderColor: '#7C3AED' },
              },
            }}
          />
        ) : (
          <Typography>{actuacion.notas_adicionales || 'Sin descripción'}</Typography>
        )}
        {editMode.notas_adicionales && (
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Button variant="contained" onClick={() => handleSave('notas_adicionales')} sx={{ backgroundColor: '#7C3AED', '&:hover': { backgroundColor: '#6D28D9' } }}>
              Guardar
            </Button>
            <Button variant="outlined" onClick={() => handleCancel('notas_adicionales')} sx={{ color: '#7C3AED', borderColor: '#7C3AED', '&:hover': { borderColor: '#6D28D9' } }}>
              Cancelar
            </Button>
          </Stack>
        )}
      </CardContent>
    </Card>
  );

  const renderDocumentsCard = () => (
    <Card sx={{ height: '100%', backgroundColor: '#1E1E1E', color: '#FFFFFF', borderRadius: 2, boxShadow: 3, position: 'relative' }}>
      <CardContent>
        <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <DescriptionIcon sx={{ mr: 1, color: '#7C3AED' }} />
          Documentos
          <IconButton
            sx={{ ml: 'auto', color: '#7C3AED' }}
            onClick={handleOpenDocumentDialog}
          >
            <AddIcon />
          </IconButton>
        </Typography>
        {documents.filter(doc => !doc.eliminado).length > 0 ? (
          documents.filter(doc => !doc.eliminado).map((doc, index) => (
            <Box key={index} sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
              <MuiLink
                href={doc.url}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: '#7C3AED', textDecoration: 'none', '&:hover': { textDecoration: 'underline' }, flexGrow: 1 }}
              >
                {doc.nombre} ({doc.tipo})
              </MuiLink>
              <IconButton
                onClick={() => handleDeleteDocument(doc.id)}
                sx={{ color: '#DC2626' }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          ))
        ) : (
          <Typography>No hay documentos asociados</Typography>
        )}
      </CardContent>
    </Card>
  );

  const renderColaboradoresCard = () => (
    <Card sx={{ height: '100%', backgroundColor: '#1E1E1E', color: '#FFFFFF', borderRadius: 2, boxShadow: 3, position: 'relative' }}>
      <CardContent>
        <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <GroupIcon sx={{ mr: 1, color: '#7C3AED' }} />
          Colaboradores
          <IconButton
            sx={{ ml: 'auto', color: '#7C3AED' }}
            onClick={handleOpenColaboradoresDialog}
          >
            <AddIcon />
          </IconButton>
        </Typography>
        {colaboradoresActivos.length > 0 ? (
          <List>
            {colaboradoresActivos.map((colaborador) => (
              <ListItem key={colaborador.id}>
                <ListItemIcon>
                  <PersonIcon sx={{ color: '#7C3AED' }} />
                </ListItemIcon>
                <ListItemText
                  primary={colaborador.nombre}
                  secondary={`${colaborador.rol} - ${colaborador.telefono}`}
                  sx={{
                    '& .MuiListItemText-primary': { color: '#FFFFFF' },
                    '& .MuiListItemText-secondary': { color: '#9CA3AF' }
                  }}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography sx={{ color: '#9CA3AF' }}>No hay colaboradores asignados</Typography>
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box sx={{ backgroundColor: '#000000', minHeight: '100vh', py: 4 }}>
      <Container maxWidth="lg">
        <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 4 }}>
          <IconButton
            component={Link}
            to="/actuaciones"
            sx={{ color: '#7C3AED' }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4" component="h1" sx={{ color: '#FFFFFF', flexGrow: 1 }}>
            {actuacion.nombre}
          </Typography>
          <Chip
            label={actuacion.estado}
            size="medium"
            onClick={handleStatusClick}
            sx={{
              ...getStatusStyle(actuacion.estado),
              fontWeight: 'bold',
              cursor: 'pointer'
            }}
          />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleStatusClose}
          >
            {estados.map((estado) => (
              <MenuItem
                key={estado}
                onClick={() => handleStatusChange(estado)}
                sx={{
                  ...getStatusStyle(estado),
                  '&:hover': {
                    opacity: 0.8,
                  },
                }}
              >
                {estado}
              </MenuItem>
            ))}
          </Menu>
        </Stack>
        <Grid container spacing={3}>
          {
            <Grid item xs={12} md={6}>
              {renderDetailsCard(
                'Detalles del Evento',
                <CalendarTodayIcon sx={{ mr: 1, color: '#7C3AED' }} />,
                null,
                [
                  { field: 'inicio', label: 'Fecha y Hora' },
                  { field: 'pais', label: 'País' },
                  { field: 'provincia', label: 'Provincia' },
                  { field: 'ciudad', label: 'Ciudad' },
                  { field: 'direccion', label: 'Dirección' },
                  { field: 'espectadores', label: 'Espectadores' },
                  { field: 'espacio_o_sala', label: 'Sala, Local, Localización' },
                  { field: 'space_type', label: 'Tipo de Espacio' }
                ]
              )}
            </Grid>
          }
          <Grid item xs={12} md={6}>
            {renderCard(
              'Información del Cliente',
              <PersonIcon sx={{ mr: 1, color: '#7C3AED' }} />,
              'cliente',
              [
                { field: 'nombre', label: 'Nombre' },
                { field: 'email', label: 'Email' },
                { field: 'telefono', label: 'Teléfono' }
              ]
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {renderCard(
              'Detalles del Bolo',
              <AttachMoneyIcon sx={{ mr: 1, color: '#7C3AED' }} />,
              'tipo_bolo',
              [
                { field: 'duracion', label: 'Duración' },
                { field: 'nombre', label: 'Nombre' },
                { field: 'precio', label: 'Precio' },
                { field: 'enlace', label: 'Enlace' }
              ]
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {renderCardProveedor(
              'Información del Proveedor',
              <BusinessIcon sx={{ mr: 1, color: '#7C3AED' }} />,
              'proveedor',
              [
                { field: 'nombre', label: 'Nombre' },
                { field: 'email', label: 'Email' },
                { field: 'telefono', label: 'Teléfono' }
              ]
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {renderCheckList()}
          </Grid>
          <Grid item xs={12}>
            {renderDescripcionCard()}
          </Grid>
          <Grid item xs={12}>
            {renderDocumentsCard()}
          </Grid>
          <Grid item xs={12}>
            {renderColaboradoresCard()}
          </Grid>
          <Grid item xs={12} md={6}>
            {renderImagenEventoCard()}
          </Grid>
        </Grid>
      </Container>
      <DocumentDialog
        open={openDocumentDialog}
        onClose={handleCloseDocumentDialog}
        newDocument={newDocument}
        handleDocumentChange={handleDocumentChange}
        handleAddDocument={handleAddDocument}
        tiposDocumento={tiposDocumento}
      />
      <Dialog
        open={openColaboradoresDialog}
        onClose={handleCloseColaboradoresDialog}
        PaperProps={{
          style: {
            backgroundColor: '#1E1E1E',
            color: '#FFFFFF',
            borderRadius: '12px',
          }
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ color: '#7C3AED', fontWeight: 'bold' }}>
          Añadir Colaboradores
        </DialogTitle>
        <DialogContent dividers sx={{ borderColor: 'rgba(255, 255, 255, 0.12)' }}>
          <List>
            <ListItem button onClick={handleSelectAllColaboradores}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedColaboradores.length === colaboradores.length}
                  indeterminate={selectedColaboradores.length > 0 && selectedColaboradores.length < colaboradores.length}
                  sx={{
                    color: '#7C3AED',
                    '&.Mui-checked': { color: '#7C3AED' },
                  }}
                />
              </ListItemIcon>
              <ListItemText primary="Seleccionar todos" sx={{ color: '#FFFFFF' }} />
            </ListItem>
            {colaboradores.map((colaborador) => (
              <ListItem key={colaborador.id} button onClick={() => handleColaboradorToggle(colaborador.id)}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={selectedColaboradores.includes(colaborador.id)}
                    sx={{
                      color: '#7C3AED',
                      '&.Mui-checked': { color: '#7C3AED' },
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={colaborador.nombre}
                  secondary={`${colaborador.rol} - ${colaborador.telefono}`}
                  sx={{
                    '& .MuiListItemText-primary': { color: '#FFFFFF' },
                    '& .MuiListItemText-secondary': { color: '#9CA3AF' }
                  }}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseColaboradoresDialog} sx={{ color: '#9CA3AF' }}>
            Cancelar
          </Button>
          <Button onClick={handleSaveColaboradores} sx={{ color: '#7C3AED' }}>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default DetalleActuacion;