// Importa las funciones necesarias de los SDKs de Firebase
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getFirestore, collection, query, where, getDocs, doc, getDoc, updateDoc } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Configuración de Firebase de tu aplicación web
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Verifica que todas las variables de entorno estén definidas
console.log("Configuración de Firebase cargada");

if (
  !firebaseConfig.apiKey ||
  !firebaseConfig.authDomain ||
  !firebaseConfig.projectId ||
  !firebaseConfig.storageBucket ||
  !firebaseConfig.messagingSenderId ||
  !firebaseConfig.appId
) {
  console.error("Falta alguna configuración de Firebase en las variables de entorno.");
}

// Inicializa Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

setPersistence(auth, browserLocalPersistence);

// Funciones de autenticación y manejo de usuarios
const signInWithPhone = async (phoneNumber, recaptchaVerifier) => {
  try {
    const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier);
    return confirmationResult;
  } catch (error) {
    console.error("Error en signInWithPhone:", error);
    throw error;
  }
};

const getUserData = async (identifier) => {
  try {
    let querySnapshot;

    if (identifier.startsWith('+')) {
      // Si viene con '+', quitamos el '+' y cualquier espacio
      const cleaned = identifier.slice(1).replace(/\s/g, '');
      // Insertamos el espacio después del '34'
      const formattedPhone = cleaned.replace(/^34/, '34 ');

      console.log("Buscando usuario con teléfono:", formattedPhone);

      const q = query(
        collection(db, "personas"),
        where("telefono", "==", formattedPhone)
      );

      querySnapshot = await getDocs(q);
    } else {
      // Si es un UID, buscamos directamente por ID del documento
      const docRef = doc(db, "personas", identifier);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() };
      }
      return null;
    }

    if (!querySnapshot.empty) {
      const userData = {
        id: querySnapshot.docs[0].id,
        ...querySnapshot.docs[0].data()
      };
      console.log("Usuario encontrado:", userData);
      return userData;
    }

    console.log("No se encontró usuario para el identificador:", identifier);
    return null;
  } catch (error) {
    console.error("Error al obtener datos del usuario:", error);
    throw error;
  }
};

const getUserOrganizations = async (userId) => {
  const collaborationsRef = collection(db, `personas/${userId}/colaboraciones`);
  const querySnapshot = await getDocs(collaborationsRef);
  return querySnapshot.docs.map(doc => doc.data());
};

const loginWithFirestoreUser = async (userId) => {
  const userRef = doc(db, 'personas', userId);
  const userSnap = await getDoc(userRef);

  if (userSnap.exists()) {
    const userData = userSnap.data();
    const organizationsSnap = await getDocs(collection(userRef, 'colaboraciones'));
    const organizations = organizationsSnap.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    return {
      id: userId,
      ...userData,
      organizations
    };
  } else {
    throw new Error('Usuario no encontrado');
  }
};

// Exporta todo al final del archivo sin duplicar identificadores
export {
  auth,
  db,
  storage,
  signInWithPhone,
  getUserData,
  getUserOrganizations,
  loginWithFirestoreUser
};
