import { storage, db } from './firebase';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import { getFirestore, getDoc } from "firebase/firestore";

/**
 * Sube un documento a Firebase Storage y devuelve la URL de descarga.
 * @param {File} file - Archivo a subir.
 * @param {string} path - Ruta en Firebase Storage.
 * @returns {Promise<string>} - URL de descarga del archivo.
 */
export const uploadDocument = async (file, path) => {
  try {
    console.log('Iniciando subida de documento a:', path);
    const storageRef = ref(storage, path);
    const snapshot = await uploadBytes(storageRef, file);
    console.log('Documento subido, obteniendo URL de descarga...');
    const downloadURL = await getDownloadURL(snapshot.ref);
    console.log('URL de descarga obtenida:', downloadURL);
    return downloadURL;
  } catch (error) {
    console.error("Error detallado al subir el documento:", error);
    throw error;
  }
};

/**
 * Actualiza los documentos asociados a una actuación en Firestore.
 * @param {string} organizacionId - ID de la organización.
 * @param {string} eventoId - ID del evento/actuación.
 * @param {Array} documentos - Lista de documentos actualizada.
 */
export const updateActuacionDocuments = async (organizacionId, eventoId, documentos) => {
  try {
    console.log('Actualizando documentos para organización:', organizacionId, 'evento:', eventoId);
    const actuacionRef = doc(db, 'organizaciones', organizacionId, 'eventos', eventoId);
    await updateDoc(actuacionRef, { documentos });
    console.log('Documentos actualizados exitosamente');
  } catch (error) {
    console.error("Error detallado al actualizar los documentos de la actuación:", error);
    throw error;
  }
};

export const markDocumentAsDeleted = async (organizacionId, eventoId, documentId) => {
  try {
    const db = getFirestore();
    const actuacionRef = doc(db, 'organizaciones', organizacionId, 'eventos', eventoId);
    
    // Obtener los documentos actuales
    const actuacionSnap = await getDoc(actuacionRef);
    if (!actuacionSnap.exists()) {
      throw new Error('La actuación no existe');
    }
    
    const documentos = actuacionSnap.data().documentos || [];
    
    // Encontrar y marcar el documento como eliminado
    const updatedDocumentos = documentos.map(doc => 
      doc.id === documentId ? { ...doc, eliminado: true } : doc
    );
    
    // Actualizar la actuación con los documentos modificados
    await updateDoc(actuacionRef, { documentos: updatedDocumentos });
    
    console.log('Documento marcado como eliminado exitosamente');
  } catch (error) {
    console.error("Error al marcar el documento como eliminado:", error);
    throw error;
  }
};
