import React from 'react';
import { Typography, Box } from '@mui/material';
import { useAuth } from '../context/AuthContext';

function OrganizationHeader() {
  const { currentOrganization } = useAuth();
  const organizationName = currentOrganization?.nombre || 'Sin organización';

  return (
    <Box sx={{ padding: 2, backgroundColor: '#1A1A1A' }}>
      <Typography 
        variant="h4" 
        component="h1" 
        align="center" 
        sx={{ 
          color: '#FFFFFF',
          fontFamily: 'Satoshi, sans-serif',
          fontWeight: 'bold'
        }}
      >
        {organizationName}
      </Typography>
    </Box>
  );
}

export default OrganizationHeader;
