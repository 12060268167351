import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline, Box } from '@mui/material';
import Sidebar from './components/Sidebar/Sidebar';
import OrganizationHeader from './components/OrganizationHeader';
import Actuaciones from './pages/Actuaciones/Actuaciones';
import Contactos from './pages/Contactos/Contactos';
import Login from './pages/Login/Login';
import { AuthProvider, useAuth } from './context/AuthContext';
import DetalleActuacion from './pages/Actuaciones/DetalleActuacion';
import AgregarActuacion from './pages/Actuaciones/AgregarActuacion';
import ContactDetails from './pages/Contactos/DetalleContacto';
import { Typography } from '@mui/material';


const darkTheme = createTheme({
  // ... (mantén la configuración del tema como estaba)
});

function AppContent() {
  const { user, loading } = useAuth();

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#000' }}>
        <Typography variant="h6" sx={{ color: '#fff' }}>Cargando...</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {user && <OrganizationHeader />}
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        {user && <Sidebar />}
        <Box component="main" sx={{ flexGrow: 1, padding: '20px' }}>
          <Routes>
            <Route path="/login" element={!user ? <Login /> : <Navigate to="/actuaciones" />} />
            <Route path="/actuaciones" element={user ? <Actuaciones /> : <Navigate to="/login" />} />
            <Route path="/contactos" element={user ? <Contactos /> : <Navigate to="/login" />} />
            <Route path="/contactos/:id" element={<ContactDetails />} />
            <Route path="/" element={<Navigate to={user ? "/actuaciones" : "/login"} />} />
            <Route path="/actuaciones/:id" element={<DetalleActuacion />} />
            <Route path="/actuaciones/agregar" element={<AgregarActuacion />} />
          </Routes>
        </Box>
      </Box>
    </Box>
  );
}


function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Router>
          <AppContent />
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
