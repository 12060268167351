import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Paper, Typography, Box, TextField, Button, CircularProgress, Snackbar, Alert } from '@mui/material';
import { signInWithPhone, getUserData, getUserOrganizations, auth, db } from '../../services/firebase';
import { setPersistence, browserLocalPersistence } from 'firebase/auth';
import { useAuth } from '../../context/AuthContext';
import { RecaptchaVerifier } from "firebase/auth";
import { loginWithFirestoreUser } from '../../services/firebase';

function Login() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [step, setStep] = useState('phone');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [testUserId, setTestUserId] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();
  const recaptchaContainerRef = useRef(null);
  const recaptchaVerifierRef = useRef(null); // Usamos useRef en lugar de useState

  useEffect(() => {
    const initializeRecaptcha = async () => {
      if (!recaptchaVerifierRef.current && recaptchaContainerRef.current) {
        try {
          const verifier = new RecaptchaVerifier(auth, recaptchaContainerRef.current, {
            'size': 'invisible',
            'callback': (response) => {
              // reCAPTCHA resuelto, permite signInWithPhoneNumber
            }
          });

          await verifier.render();
          recaptchaVerifierRef.current = verifier;
          console.log("RecaptchaVerifier inicializado correctamente.");
        } catch (error) {
          console.error("Error al inicializar RecaptchaVerifier:", error);
        }
      }
    };

    initializeRecaptcha();
  }, []);

  const formatPhoneNumber = (number) => {
    // Eliminar todos los caracteres no numéricos
    const cleaned = number.replace(/\D/g, '');

    // Si ya empieza con 34, lo dejamos, si no, lo añadimos
    const withPrefix = cleaned.startsWith('34') ? cleaned : `34${cleaned}`;

    // Añadir el '+'
    return `+${withPrefix}`;
  };

  const handlePhoneSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
      console.log("Intentando login con número:", formattedPhoneNumber);

      await setPersistence(auth, browserLocalPersistence); // Configurar persistencia local
      const confirmationResult = await signInWithPhone(formattedPhoneNumber, recaptchaVerifierRef.current);
      setConfirmationResult(confirmationResult);
      setStep('code');
    } catch (error) {
      console.error("Error en handlePhoneSubmit:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const userCredential = await confirmationResult.confirm(verificationCode);
      const firebaseUser = userCredential.user;

      console.log("Usuario autenticado:", firebaseUser);
      console.log("Número de teléfono del usuario:", firebaseUser.phoneNumber);

      const userData = await getUserData(firebaseUser.phoneNumber);

      if (userData) {
        const organizations = await getUserOrganizations(userData.id);
        login({ ...userData, organizations });
        navigate('/actuaciones');
      } else {
        console.error('Usuario no encontrado en Firestore. Firebase User:', firebaseUser);
        setError('Usuario no encontrado en la base de datos');
      }
    } catch (error) {
      console.error("Error detallado en handleCodeSubmit:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleTestLogin = async () => {
    if (!testUserId) {
      setError('Por favor, introduce un ID de usuario para el login de prueba');
      return;
    }
    setLoading(true);
    try {
      const userData = await loginWithFirestoreUser(testUserId);
      if (userData) {
        login(userData); // Usa la función login del contexto
        navigate('/actuaciones');
      } else {
        setError('Usuario no encontrado');
      }
    } catch (error) {
      console.error("Error en el login de prueba:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{
      backgroundColor: '#000000',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Container component="main" maxWidth="xs">
        <Paper elevation={3} sx={{
          backgroundColor: '#1E1E1E',
          borderRadius: '16px',
          padding: 4,
          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '15vh', // Esto asegura que el contenedor ocupe toda la altura de la pantalla
          }}>
            <img src="/images/logo.png" alt="Huming Logo" style={{ width: '60%', height: 'auto' }} />
          </div>

          <Typography component="h1" variant="h4" align="center" gutterBottom sx={{ color: '#FFFFFF', fontWeight: 'bold' }}>
            Iniciar Sesión
          </Typography>
          {step === 'phone' ? (
            <Box component="form" onSubmit={handlePhoneSubmit} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="phone"
                label="Número de Teléfono"
                name="phone"
                autoComplete="tel"
                autoFocus
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                helperText="Introduce el número sin el prefijo +34"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: '#2D3748' },
                    '&:hover fieldset': { borderColor: '#4A5568' },
                    '&.Mui-focused fieldset': { borderColor: '#7C3AED' },
                  },
                  '& .MuiInputLabel-root': { color: '#A0AEC0' },
                  '& .MuiInputBase-input': { color: '#FFFFFF' },
                  '& .MuiFormHelperText-root': { color: '#A0AEC0' },
                }}
              />
              <div id="recaptcha-container" ref={recaptchaContainerRef}></div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: '#7C3AED',
                  color: '#FFFFFF',
                  '&:hover': { backgroundColor: '#6D28D9' },
                  '&:disabled': {
                    backgroundColor: '#4A5568',
                    color: '#A0AEC0'
                  },
                  borderRadius: '8px',
                  textTransform: 'none',
                  fontWeight: 'bold',
                  py: 1.5,
                  fontSize: '1rem',
                  boxShadow: '0 4px 6px -1px rgba(124, 58, 237, 0.5), 0 2px 4px -1px rgba(124, 58, 237, 0.06)'
                }}
                disabled={loading || !phoneNumber}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Enviar Código'}
              </Button>
              {/*
              <Typography variant="h6" align="center" gutterBottom sx={{ color: '#FFFFFF' }}>
                O
              </Typography>

              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="testUserId"
                label="ID de Usuario de Prueba"
                name="testUserId"
                value={testUserId}
                onChange={(e) => setTestUserId(e.target.value)}
                helperText="Introduce el ID del usuario de Firestore para el login de prueba"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: '#2D3748' },
                    '&:hover fieldset': { borderColor: '#4A5568' },
                    '&.Mui-focused fieldset': { borderColor: '#7C3AED' },
                  },
                  '& .MuiInputLabel-root': { color: '#A0AEC0' },
                  '& .MuiInputBase-input': { color: '#FFFFFF' },
                  '& .MuiFormHelperText-root': { color: '#A0AEC0' },
                }}
              />
              <Button
                fullWidth
                variant="outlined"
                sx={{
                  mt: 2,
                  color: '#7C3AED',
                  borderColor: '#7C3AED',
                  '&:hover': {
                    borderColor: '#6D28D9',
                    backgroundColor: 'rgba(124, 58, 237, 0.1)'
                  },
                  '&:disabled': {
                    borderColor: '#4A5568',
                    color: '#A0AEC0'
                  },
                  borderRadius: '8px',
                  textTransform: 'none',
                  fontWeight: 'bold',
                  py: 1.5,
                  fontSize: '1rem'
                }}
                onClick={handleTestLogin}
                disabled={loading || !testUserId}
              >
                Login de Prueba (Firestore)
              </Button>

*/}
            </Box>
          )

            : (
              <Box component="form" onSubmit={handleCodeSubmit} noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="code"
                  label="Código de Verificación"
                  name="code"
                  autoComplete="off"
                  autoFocus
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': { borderColor: '#2D3748' },
                      '&:hover fieldset': { borderColor: '#4A5568' },
                      '&.Mui-focused fieldset': { borderColor: '#7C3AED' },
                    },
                    '& .MuiInputLabel-root': { color: '#A0AEC0' },
                    '& .MuiInputBase-input': { color: '#FFFFFF' },
                  }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: '#7C3AED',
                    '&:hover': { backgroundColor: '#6D28D9' },
                    borderRadius: '8px',
                    textTransform: 'none',
                    fontWeight: 'bold',
                    py: 1.5
                  }}
                  disabled={loading || verificationCode.length !== 6}
                >
                  {loading ? <CircularProgress size={24} /> : 'Verificar'}
                </Button>
              </Box>
            )}
        </Paper>
      </Container>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
        <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%', backgroundColor: '#DC2626', color: '#FFFFFF' }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Login;
