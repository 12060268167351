import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  Drawer, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  Typography, 
  Box,
  Divider,
  Button,
  Menu,
  MenuItem
} from '@mui/material';
import { ExitToApp, KeyboardArrowDown } from '@mui/icons-material';
import { useAuth } from '../../context/AuthContext';

function Sidebar() {
  const { user, currentOrganization, setCurrentOrganization, logout } = useAuth();
  const [organizations, setOrganizations] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.organizations) {
      setOrganizations(user.organizations);
    }
  }, [user]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOrganizationChange = (org) => {
    console.log("Cambiando a organización:", org);
    setCurrentOrganization(org);
    handleClose();
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 240,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 240,
          boxSizing: 'border-box',
          backgroundColor: '#111111', // Un gris ligeramente más claro que el negro
          color: '#FFFFFF',
          borderRight: 'none',
        },
      }}
    >
      <Box sx={{ 
        padding: 3, 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'flex-start',
        gap: 2
      }}>
        <img src="/images/logo.png" alt="Huming Logo" style={{ width: '80%', height: 'auto' }} />
        <Button 
          variant="outlined" 
          size="small" 
          endIcon={<KeyboardArrowDown />}
          onClick={handleClick}
          sx={{ 
            color: '#FFFFFF', 
            borderColor: '#5040FF',
            '&:hover': { borderColor: '#5040FF', backgroundColor: 'rgba(80, 64, 255, 0.1)' }
          }}
        >
          CAMBIAR
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {organizations.map((org) => (
            <MenuItem key={org.id} onClick={() => handleOrganizationChange(org)}>
              {org.nombre}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <Divider sx={{ backgroundColor: '#333', margin: '0 16px' }} />
      <List sx={{ padding: '16px 8px' }}>
        <ListItem 
          button 
          component={Link} 
          to="/actuaciones" 
          sx={{ 
            borderRadius: '12px',
            mb: 1,
            '&:hover': { 
              backgroundColor: 'rgba(80, 64, 255, 0.1)',
            }
          }}
        >
          <ListItemIcon>
            <img src="/images/actuaciones-icon.png" alt="Actuaciones" style={{ width: '24px', height: '24px' }} />
          </ListItemIcon>
          <ListItemText 
            primary="Mis actuaciones" 
            primaryTypographyProps={{ 
              fontWeight: 'bold',
              color: '#FFFFFF'
            }} 
          />
        </ListItem>
        <ListItem 
          button 
          component={Link} 
          to="/contactos" 
          sx={{ 
            borderRadius: '12px',
            '&:hover': { 
              backgroundColor: 'rgba(124, 58, 237, 0.1)',
            }
          }}
        >
          <ListItemIcon>
            <img src="/images/contactos-icon.png" alt="Contactos" style={{ width: '24px', height: '24px' }} />
          </ListItemIcon>
          <ListItemText 
            primary="Contactos" 
            primaryTypographyProps={{ 
              fontWeight: 'bold',
              color: '#FFFFFF'
            }} 
          />
        </ListItem>
      </List>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ padding: '16px', textAlign: 'center' }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          ¡Hola {user?.nombre || 'Usuario'}!
        </Typography>
        {currentOrganization && (
          <Typography variant="body2" sx={{ color: '#A0AEC0' }}>
            {currentOrganization.nombre}
          </Typography>
        )}
      </Box>
      <Divider sx={{ backgroundColor: '#333', margin: '0 16px' }} />
      <List sx={{ padding: '16px 8px' }}>
        <ListItem 
          button 
          onClick={handleLogout} // Cambia esto de Link a onClick
          sx={{ 
            borderRadius: '12px',
            '&:hover': { 
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            }
          }}
        >
          <ListItemIcon><ExitToApp sx={{ color: '#FFFFFF' }} /></ListItemIcon>
          <ListItemText 
            primary="Cerrar Sesión" 
            primaryTypographyProps={{ 
              fontWeight: 'bold',
              fontSize: '0.9rem',
              color: '#FFFFFF'
            }} 
          />
        </ListItem>
      </List>
    </Drawer>
  );
}

export default Sidebar;